/**
 * Root client file
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

// React stuffs
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import userExpiration from './services/userExpiration'
import * as serviceWorker from './services/service-worker'
import './services/i18n'
import { store, persistor } from './store'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

// history from reducer
import { history } from './reducers'

// Styling
import './styles/index.scss'

// Components
import App from './App'
//Localization
import './services/i18n'

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn:
			'https://eb0d7bca1018414a917647c828ababd9@o381714.ingest.sentry.io/5725747',
		integrations: [new Integrations.BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0
	})
}

// make users renew their login even sometimes to refetch their rights (and other data) from the backend
userExpiration.purgeExpired()

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('app')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register('worker.min.js')
