/**
 * Reducer adding values to subJobTypes attributes
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { workLogTypes as types } from '../actions/types'
import moment from 'moment'

const initialState = {
	loading: false,
	error: ''
}

export const workLogReducer = (state = initialState, action) => {
	switch (action.type) {
		default:
			return state
	}
}

export const workLogSelectors = {
	getField: (state = initialState, field) => state[field],
	getWorkLog: (state = initialState) => state
}
