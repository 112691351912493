/**
 * work event action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { workEventTypes as types } from './types'
import { workEventData } from './utils'

export const setWorkEvent = workEvent => {
	return {
		type: types.setWorkEvent,
		workEvent
	}
}

export const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

export const addSubJob = (subJob, isEdit = false) => {
	return {
		type: types.addSubJobType,
		subJob,
		isEdit
	}
}

export const changeFieldAttributeValue = (name, value, id) => {
	const nameInteger = parseInt(name)
	return {
		type: types.changeFieldAttributeValue,
		name: nameInteger,
		value,
		id
	}
}

export const removeSubJob = subJob => {
	return {
		type: types.removeSubJob,
		subJob
	}
}

export const resetStates = () => {
	return {
		type: types.resetState
	}
}

// Server side actions:

export const getAll = () => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/default/work_event'
			}
		}
	}
}

export const create = workEvent => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/default/work_event',
				data: workEventData(workEvent)
			}
		}
	}
}

export const createWithRelations = workEvent => {
	return {
		type: types.createWithRelations,
		payload: {
			request: {
				method: 'POST',
				url: '/work_in_apartment/createWorkEvent',
				data: workEvent
			}
		}
	}
}

export const save = workEvent => {
	return {
		type: types.save,
		payload: {
			request: {
				method: 'PATCH',
				url: '/work_in_apartment/saveWorkEvent',
				data: workEvent
			}
		}
	}
}

export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/default/work_event/' + id
			}
		}
	}
}

export const update = (updateData, id) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/work_event/' + id,
				data: updateData
			}
		}
	}
}

export const deleteWorkEvent = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/work_event/' + id
			}
		}
	}
}

export const updateRelation = (updateData, id) => {
	// CityCharge, JobType, Room, User, WorkInApartment
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/work_event/' + id,
				data: updateData
			}
		}
	}
}

export const deleteWorkEventRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/work_event/' + id,
				data: deleteData
			}
		}
	}
}
