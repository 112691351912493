/**
 * Job reducer
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { jobTypes as types, loginTypes, fileTypes } from '../actions/types'

const initialState = {
	job: {
		address: '',
		town: '',
		housingCo: '',
		landLord: '',
		landLordPhone: '',
		propertyMaintenance: '',
		propertyMaintenancePhone: '',
		propertyMaintenanceEmail: '',
		propertyMaintenanceAddress: '',
		propertyMaintenanceCity: '',
		inspector: '',
		workChargePerson: '',
		toBeBilled: ''
	},
	//Adding new contact to a landlord and property maintenance
	landLordContact: {
		name: '',
		phone: '',
		email: ''
	},
	landLordContacts: [],
	propertyMaintenanceContact: {
		name: '',
		phone: '',
		email: ''
	},
	vtFile: null,
	loading: false,
	jobs: [],
	getJob: null,
	error: ''
}

export const jobReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.changeField:
			return {
				...state,
				job: {
					...state.job,
					[action.name]: action.value
				},
				error: 'testi'
			}
		case types.changeFieldMaintenanceContact:
			return {
				...state,
				propertyMaintenanceContact: {
					...state.propertyMaintenanceContact,
					[action.name]: action.value
				},
				error: initialState.error
			}

		case types.changeFieldLandLordContact:
			return {
				...state,
				landLordContact: {
					...state.landLordContact,
					[action.name]: action.value
				},
				error: initialState.error
			}

		case types.changeFieldLandLordContactsArray:
			const modifiedArray = [...state.landLordContacts]
			modifiedArray[action.index] = {
				...modifiedArray[action.index],
				[action.name]: action.value
			}
			return {
				...state,
				landLordContacts: modifiedArray,
				error: initialState.error
			}

		case types.landLordAdd:
			return {
				...state,
				landLordContacts: [...state.landLordContacts, action.payload.data],
				loading: false,
				landLordContact: initialState.landLordContact
			}

		case types.landLordDelete:
			return {
				...state,
				landLordContacts: [
					...state.landLordContacts.filter(
						landLordContact =>
							landLordContact.name.toLowerCase() !== action.data.toLowerCase()
					)
				]
			}

		case types.create:
			return {
				...state,
				loading: true
			}

		case types.createSuccess:
			return {
				...state,
				job: {
					...state.job,
					[action.name]: action.value
				},
				loading: false
			}

		case types.createFail:
			return {
				...state,
				loading: false,
				error: action.error
			}

		case fileTypes.loadNewVt:
			return {
				...state,
				loading: true
			}
		case fileTypes.loadNewVtSuccess:
			return {
				...state,
				vtFile: action.payload.data
			}
		case fileTypes.loadNewVtFail:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case types.getJobByIdSuccess:
			return {
				...state,
				getJob: action.payload.data
			}
		case types.getAllJobsSuccess:
			return {
				...state,
				jobs: action.payload.data
			}

		case types.initializeNewJobStates:
			return {
				...state,
				job: initialState.job,
				getJob: initialState.getJob,
				error: initialState.error,
				vtFile: initialState.vtFile,
				loading: initialState.loading,
				landLordContact: initialState.landLordContact,
				landLordContacts: initialState.landLordContacts,
				propertyMaintenanceContact: initialState.propertyMaintenanceContact,
				roles: initialState.roles
			}
		case loginTypes.logout:
			return {
				...initialState
			}

		default:
			return state
	}
}

export const jobSelectors = {
	getField: (state = initialState, field) => state[field],
	getJob: (state = initialState) => state.job,
	getGettedJob: (state = initialState) => state.getJob,
	getAllJobs: (state = initialState) => state.jobs,
	getVT: (state = initialState) => state.vtFile,
	getError: (state = initialState) => state.error,
	getAll: (state = initialState) => state
}
