/**
 * Login action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { loginTypes as types } from './types'

export const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

export const sendSMS = phoneNumber => {
	return {
		type: types.sendSMS,
		payload: {
			request: {
				method: 'POST',
				url: '/bearer/textmessage',
				data: {
					phoneNumber
				}
			}
		}
	}
}

export const login = (phoneNumber, code) => {
	return {
		type: types.login,
		payload: {
			request: {
				method: 'POST',
				url: '/bearer',
				data: {
					phoneNumber,
					code
				}
			}
		}
	}
}

export const getCurrentUser = () => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/bearer'
			}
		}
	}
}

export const logout = {
	type: types.logout
}
