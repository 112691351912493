import { GetAction } from './action'
import { roleTypeTypes as types } from './types'
import { roleTypeData } from './utils'
import { RoleType } from '../models/roleType'

export const get = (): GetAction<types> => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/default/role_type'
			}
		}
	}
}

export const create = (roleType: RoleType) => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/default/role_type',
				data: roleTypeData(roleType)
			}
		}
	}
}

export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/default/role_type/' + id
			}
		}
	}
}

export const update = (updateData: Partial<RoleType>, id: number) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/role_type/' + id,
				data: updateData
			}
		}
	}
}

export const deleteRoleType = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/role_type/' + id
			}
		}
	}
}
