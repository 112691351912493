/**
 * Documents action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { fileTypes as types } from './types'

export const create = data => {
	return {
		type: types.postReport,
		payload: {
			request: {
				method: 'POST',
				url: `/file`,
				data
			}
		}
	}
}

export const createLink = data => {
	return {
		type: types.createLink,
		payload: {
			request: {
				method: 'POST',
				url: `/file/createLink`,
				data
			}
		}
	}
}

export const update = (id, data) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: `/default/file/${id}`,
				data
			}
		}
	}
}

export const downloadFile = id => {
	const url = `/file/${id}`

	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: url
			}
		}
	}
}

export const getByWorkInAddress = (id, fileType) => {
	const url = `/file?fileType=${fileType}&workInAddress=${id}`

	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: url
			}
		}
	}
}

export const getByWorkInApartment = (id, fileType) => {
	const url = `/file?fileType=${fileType}&workInApartment=${id}`
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: url
			}
		}
	}
}

export const createMeasurementReport = (data, params) => {
	return {
		type: types.createMeasurementReport,
		payload: {
			request: {
				method: 'POST',
				url: `/file/report/measurement`,
				data,
				params
			}
		}
	}
}
export const saveMeasurementReport = (data, params) => {
	return {
		type: types.createMeasurementReport,
		payload: {
			request: {
				method: 'POST',
				url: `/file/report/measurement`,
				data,
				params,
				responseType: 'arraybuffer'
			}
		}
	}
}
export const createJobSpecificationReport = (data, params) => {
	return {
		type: types.createJobSpecificationReport,
		payload: {
			request: {
				method: 'POST',
				url: `/file/report/jobspecification`,
				data,
				params
			}
		}
	}
}
export const saveJobSpecificationReport = (data, params) => {
	return {
		type: types.createJobSpecificationReport,
		payload: {
			request: {
				method: 'POST',
				url: `/file/report/jobspecification`,
				data,
				params,
				responseType: 'arraybuffer'
			}
		}
	}
}

//Actions for updating relations

export const updateFileRelation = (fileId, field, updatedata) => {
	// User, WorkInApartmen
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/file/' + fileId,
				data: { [field]: updatedata }
			}
		}
	}
}

export const deleteFile = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/file/delete/' + id
			}
		}
	}
}

export const deleteFileRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/file/' + id,
				data: deleteData
			}
		}
	}
}
