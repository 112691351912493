/**
 * Measurement action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2020
 */

import { measurementTypes as types } from './types'
import { measurementData } from './utils'

export const get = () => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/default/measurement'
			}
		}
	}
}

export const create = measurement => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/default/measurement',
				data: measurementData(measurement)
			}
		}
	}
}

export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/default/measurement/' + id
			}
		}
	}
}

export const update = (updateData, id) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/measurement/' + id,
				data: updateData
			}
		}
	}
}

export const deleteMeasurement = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/measurement/' + id
			}
		}
	}
}

export const updateRelation = (updateData, id) => {
	// Surface or machineType
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/measurement/' + id,
				data: updateData
			}
		}
	}
}

export const deleteMeasurementRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/measurement/' + id,
				data: deleteData
			}
		}
	}
}
