/**
 * Footer
 *
 * @author <@vertics.co>
 *
 */
import React from 'react'

const Footer = () => {
	return (
		<div className="footer">
			<span> </span>
		</div>
	)
}

export default Footer
