/**
 * User reducer
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { loginTypes as types, userTypes } from '../actions/types'

const initialState = {
	bearer: '',
	user: {},
	users: [],
	deleteId: null
}

export const userReducer = (state = initialState, action) => {
	if (action.type.endsWith('_FAIL')) {
		if (
			action.error &&
			action.error.response &&
			action.error.response.data.httpCode === 401 &&
			action.error.response.data.name === 'AuthorizationRequiredError'
		) {
			localStorage.setItem('bearer', '')
			return {
				...initialState
			}
		}
	}
	switch (action.type) {
		case types.loginSuccess:
			return {
				...state,
				bearer: action.payload.data.bearer,
				user: action.payload.data.user
			}
		case types.logout:
			localStorage.setItem('bearer', '')
			return {
				...initialState
			}
		case userTypes.getAllUsersSuccess:
			return {
				...state,
				users: action.payload.data
			}

		case userTypes.changeField:
			return {
				...state,
				user: {
					...state.user,
					[action.name]: action.value
				}
			}
		case userTypes.changeFieldUsers:
			const modifiedUsers = [...state.users]
			modifiedUsers[action.index] = {
				...modifiedUsers[action.index],
				[action.name]: action.value
			}
			return {
				...state,
				users: modifiedUsers
			}

		case userTypes.setdDeleteUser:
			const deletingUser = [...state.users]
			deletingUser[action.index] = {
				...deletingUser[action.index],
				deleteId: action.id
			}
			return {
				...state,
				users: deletingUser
			}

		default:
			return state
	}
}

export const userSelectors = {
	getUser: (state = initialState) => state.user,
	getUsers: (state = initialState) => state.users,
	getBearer: (state = initialState) => state.bearer,
	isAuthenticated: (state = initialState) => {
		return (
			userSelectors.getBearer(state.user) && userSelectors.getUser(state.user)
		)
	}
}
