/**
 * JobType action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { jobTypeTypes as types } from './types'
import { jobTypeData } from './utils'

export const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

export const changeFieldValueOfAttributes = (name, value) => {
	return {
		type: types.changeFieldValueOfAttributes,
		name,
		value
	}
}

export const resetStates = () => {
	return {
		type: types.resetState
	}
}

export const get = () => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/job_type'
			}
		}
	}
}

export const getMachineType = () => {
	return {
		type: types.getMachineType,
		payload: {
			request: {
				method: 'GET',
				url: '/default/machine_type'
			}
		}
	}
}

export const installMachine = data => {
	return {
		type: types.installMachine,
		payload: {
			request: {
				method: 'POST',
				url: '/default/machine_installment',
				data
			}
		}
	}
}

export const deleteMachine = id => {
	return {
		type: types.deleteMachine,
		payload: {
			request: {
				method: 'DELETE',
				url: '/machine/' + id
			}
		}
	}
}

export const updateMachineInstallRelation = (machineId, data) => {
	return {
		type: types.installMachine,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/machine_installment/' + machineId,
				data
			}
		}
	}
}

export const updateMachine = (machineId, data) => {
	return {
		type: types.updateMachine,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/machine_installment/' + machineId,
				data
			}
		}
	}
}

export const postMaterial = data => {
	return {
		type: types.createMaterial,
		payload: {
			request: {
				method: 'POST',
				url: '/default/material',
				data
			}
		}
	}
}

export const updateRelationMaterial = (materialId, data) => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/material/' + materialId,
				data: data
			}
		}
	}
}

export const create = jobType => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/default/job_type',
				data: jobTypeData(jobType)
			}
		}
	}
}

export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/default/job_type/' + id
			}
		}
	}
}

export const update = (updateData, id) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/job_type/' + id,
				data: updateData
			}
		}
	}
}

export const deletejobType = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/job_type/' + id
			}
		}
	}
}

export const updateRelation = (updateData, id) => {
	//SubJobType or WorkEvent
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/job_type/' + id,
				data: updateData
			}
		}
	}
}

export const deletejobTypeRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/job_type/' + id,
				data: deleteData
			}
		}
	}
}

export const createMeasurement = data => {
	return {
		type: types.createMeasurement,
		payload: {
			request: {
				method: 'POST',
				url: '/default/measurement',
				data
			}
		}
	}
}

export const updateRelationMeasurement = (data, id) => {
	return {
		type: types.updateRelationMeasurement,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/measurement/' + id,
				data
			}
		}
	}
}

export const updateSurface = (data, surfaceId) => {
	return {
		type: types.updateSurface,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/surface/' + surfaceId,
				data
			}
		}
	}
}

export const createSurface = data => {
	return {
		type: types.createSurface,
		payload: {
			request: {
				method: 'POST',
				url: '/default/surface',
				data
			}
		}
	}
}
