/**
 * Error message component
 * Display error message as a banner
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React from 'react'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import { helperActions } from '../../../actions'

class Notification extends React.Component {
	state = {
		show: false,
		vertical: 'top',
		horizontal: 'center',
		notificationTime: ''
	}

	handleClose = () => {
		this.setState({ show: false })
	}

	componentDidMount() {
		if (this.props.notification) {
			this.setState({ show: true })
		}
	}

	unShowNotification = () => {
		this.setState(
			{
				show: false
			},
			() => {
				this.props.clearNotification()
			}
		)
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.notification !== this.props.notification &&
			this.props.notification
		) {
			this.setState({
				show: true,
				notificationTime: window.setTimeout(this.unShowNotification, 5000)
			})
		}
	}
	componentWillUnmount() {
		window.clearTimeout(this.state.notificationTime)
		this.setState({
			show: false
		})
	}

	render() {
		const { notification } = this.props
		const { show } = this.state

		if (!notification) return null

		return (
			<div className="notification-message-container">
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					key={`${this.state.vertical},${this.state.horizontal}`}
					open={show}
					onClose={this.handleClose}
					ContentProps={{
						'aria-describedby': 'message-id'
					}}
					message={<span id="message-id">{notification}</span>}
				/>
			</div>
		)
	}
}

export default connect(
	state => ({
		notification: state.notification.message
	}),
	dispatch => ({
		clearNotification: () => dispatch(helperActions.clearNotification())
	})
)(Notification)
