/**
 * User action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { userTypes as types } from './types'
import { userData, userAdminData } from './utils'

export const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

export const changeFieldUsers = (name, value, index) => {
	return {
		type: types.changeFieldUsers,
		name,
		value,
		index
	}
}

export const createUser = data => {
	return {
		type: types.createUser,
		payload: {
			request: {
				method: 'POST',
				url: '/user',
				data: userData(data)
			}
		}
	}
}

export const createAdminUser = data => {
	return {
		type: types.createAdminUser,
		payload: {
			request: {
				method: 'POST',
				url: '/admin/user',
				data: userAdminData(data)
			}
		}
	}
}

export const getAllUsers = () => {
	return {
		type: types.getAllUsers,
		payload: {
			request: {
				method: 'GET',
				url: '/user'
			}
		}
	}
}

export const getUserById = id => {
	return {
		type: types.getUserById,
		payload: {
			request: {
				method: 'GET',
				url: '/user/' + id
			}
		}
	}
}

export const updateUser = (id, userData) => {
	return {
		type: types.patchUser,
		payload: {
			request: {
				method: 'PATCH',
				url: '/user/' + id,
				data: userData
			}
		}
	}
}

export const updateUserRelation = (id, userData) => {
	//WorkEvents, Roles, Comments, Files
	return {
		type: types.updateUserRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/relation/user/' + id,
				data: userData
			}
		}
	}
}

export const deleteUser = id => {
	return {
		type: types.deleteUser,
		payload: {
			request: {
				method: 'DELETE',
				url: '/admin/user/' + id
			}
		}
	}
}

export const restoreUser = id => {
	return {
		type: types.restoreUser,
		payload: {
			request: {
				method: 'PATCH',
				url: '/admin/user/restore/' + id
			}
		}
	}
}

export const deleteUserRelation = (id, relationData) => {
	return {
		type: types.deleteUserRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/relation/user/' + id,
				data: relationData
			}
		}
	}
}
