/**
 * Person action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2020
 */

import { personTypes as types } from './types'
import { personData } from './utils'

export const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

export const get = (onlyActive = true) => {
	let url = '/default/person'
	if (!onlyActive) {
		url += '?onlyActive=false'
	}

	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url
			}
		}
	}
}

export const create = person => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/default/person',
				data: personData(person)
			}
		}
	}
}

export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/default/person/' + id
			}
		}
	}
}

export const update = (updateData, id) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/person/' + id,
				data: updateData
			}
		}
	}
}

export const deletePerson = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/person/' + id
			}
		},
		id
	}
}

export const updateRelation = (updateData, id) => {
	// Company or WorkInApartment
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/person/' + id,
				data: updateData
			}
		}
	}
}

export const deletePersonRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/person/' + id,
				data: deleteData
			}
		}
	}
}
