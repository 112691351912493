/**
 * Helpers for action creators
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { notificationTypes, errorTypes } from './types'

export const clearErrorMessage = () => {
	return {
		type: 'CLEAR_ERROR_MESSAGE'
	}
}

export const clearNotification = () => {
	return {
		type: notificationTypes.clearNotification
	}
}

export const showNotification = message => {
	return {
		type: notificationTypes.showNotification,
		payload: {
			data: { message }
		}
	}
}

export const showError = message => {
	return {
		type: errorTypes.showError,
		payload: {
			data: { message }
		}
	}
}
