/**
 * Setup service worker
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 * @copyright Vertics Co 2020
 */

/**
 * Register service worker
 * In Production, service worker's URL is service-worker.js
 * which has caching and service worker's logics
 *
 * @param {String} url path of bundled service worker file
 */
export const register = url => {
	if (!navigator.serviceWorker || !url) return
	const workerUrl =
		process.env.NODE_ENV === 'development' ? url : 'service-worker.js'
	navigator.serviceWorker.register(workerUrl).catch(error => {})
}

/**
 * Unregister service worker
 *
 */
export const unregister = () => {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.getRegistrations().then(registrations => {
			registrations.forEach(registration => {
				registration.unregister()
			})
		})
	}
}
