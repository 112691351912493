/**
 * Configure redux store
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { routerMiddleware } from 'connected-react-router'
import axios from 'axios'
import axiosMiddleware, { multiClientMiddleware } from 'redux-axios-middleware'
import { createLogger } from 'redux-logger'

import { rootReducer, history } from './reducers'
import { PERSIST_KEY, BASE_URL, REPORTING_BASE_URL } from './constants'

// Create client alias
// Used in action creators
const client = axios.create({
	baseURL: BASE_URL,
	responseType: 'json'
})

const reportingClient = axios.create({
	baseURL: REPORTING_BASE_URL,
	responseType: 'json'
})

const options = {
	returnRejectedPromiseOnError: true,
	interceptors: {
		request: [
			({}, config) => {
				// Add authentication tokens to request header
				config.headers = {
					authorization: `Bearer ${localStorage.getItem('bearer')}`
				}
				return config
			}
		]
	}
}

// Config redux-persist
const persistConfig = {
	key: PERSIST_KEY,
	storage,
	blacklist: ['form', 'router', 'error', 'login']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

// Define middleware to use
let middleware = [
	thunk,
	routerMiddleware(history),
	multiClientMiddleware(
		{
			default: { client },
			reporting: { client: reportingClient }
		},
		options
	)
]
const isProduction = process.env.NODE_ENV === 'production'
if (!isProduction) {
	// const logger = createLogger()
	// middleware.push(logger)
}

const tools = [applyMiddleware(...middleware)]
if (window.__REDUX_DEVTOOLS_EXTENSION__)
	tools.push(window.__REDUX_DEVTOOLS_EXTENSION__())

// Create redux store
const store = createStore(persistedReducer, compose(...tools))
let persistor = persistStore(store)

export { store, persistor }
