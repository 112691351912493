/**
 * Comment action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2020
 */

import { commentTypes as types } from './types'
import { commentData } from './utils'

export const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

export const get = () => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/default/comment'
			}
		}
	}
}

export const create = comment => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/default/comment',
				data: commentData(comment)
			}
		}
	}
}

export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/default/comment/' + id
			}
		}
	}
}

export const update = (updateData, id) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/comment/' + id,
				data: updateData
			}
		}
	}
}

export const deleteComment = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/comment/' + id
			}
		}
	}
}

export const updateRelation = (updateData, id) => {
	//WorkInAddress or WorkInApartment
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/comment/' + id,
				data: updateData
			}
		}
	}
}

export const deleteCommentRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/comment/' + id,
				data: deleteData
			}
		}
	}
}
