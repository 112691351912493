import moment from 'moment'

// APARTMENT
export const apartmentData = apartment => {
	const data = {
		apartmentNumber: apartment.apartmentNumber,
		residentName: apartment.residentName,
		residentPhoneNumber: apartment.residentPhoneNumber,
		residentEmail: apartment.residentEmail,
		inspectionPresence: apartment.inspectionPresence
	}
	return data
}

export const bankAccountData = bankAccount => {
	const data = {
		message: bankAccount.message,
		email: bankAccount.email,
		link: bankAccount.link,
		name: bankAccount.name
	}
	return data
}

// CITY CHARGE
export const cityChargeData = cityCharge => {
	const data = {
		date: cityCharge.cityChargeDate,
		amount: cityCharge.cityChargeAmount
	}
	return data
}
// COMMENT
export const commentData = comment => {
	const data = {
		description: comment.description,
		resolved: comment.resolved
	}
	return data
}
// COMPANY
export const companyData = company => {
	const data = {
		name: company.name,
		phoneNumber: company.phoneNumber,
		email: company.email,
		address: company.address,
		city: company.city,
		type: company.type
	}
	return data
}
// FILE
export const fileData = file => {
	const data = {
		key: file.key,
		contentType: file.contentType,
		createdAt: file.createdAt,
		status: file.status
	}
	return data
}
// JOBTYPE
export const jobTypeData = jobType => {
	const data = {
		name: jobType.name
	}
	return data
}

// MACHINEINSTALLMENT
export const machineInstallmentData = machineInstallment => {
	const data = {
		quantity: machineInstallment.quantity,
		startDate: machineInstallment.startDate,
		endDate: machineInstallment.endDate
	}
	return data
}

// MACHINETYPE
export const machineTypeData = machineType => {
	const data = {
		name: machineType.name
	}
	return data
}

// MEASUREMENT
export const measurementData = measurement => {
	const data = {
		type: measurement.type,
		value: measurement.value,
		date: measurement.date,
		dryCompareValue: measurement.dryCompareValue,
		rh: measurement.rh,
		temperature: measurement.temperature,
		abs: measurement.abs,
		depth: measurement.depth,
		sampleMeasurement: measurement.sampleMeasurement,
		drillMeasurement: measurement.drillMeasurement
	}
	return data
}

// PERSON
export const personData = person => {
	const data = {
		name: person.name,
		phoneNumber: person.phoneNumber,
		email: person.email,
		type: person.type,
		companies: person.companies,
		roleTypeId: person.roleTypeId
	}
	return data
}

// ROLE TYPE
export const roleTypeData = roleType => {
	const data = {
		//id: roleType.id,
		name: roleType.name
	}
	return data
}

// ROOM
export const roomData = room => {
	const data = {
		type: room.type,
		tag: room.tag
	}
	return data
}

// SUBJOBTYPE
export const subJobTypeData = subJobType => {
	const data = {
		name: subJobType.name
	}
	return data
}

// SURFACE
export const surfaceData = surface => {
	const data = {
		type: surface.type,
		material: surface.material
	}
	return data
}

// USER
export const userData = user => {
	const data = {
		firstName: user.firstName,
		lastName: user.lastName,
		email: user.email,
		phoneNumber: user.phoneNumber,
		canEditWorkLog: user.canEditWorkLog,
		isInspector: user.isInspector,
		isAdmin: user.isAdmin,
		canEditReport: user.canEditReport,
		canDeleteTask: user.canDeleteTask
	}
	return data
}

// ADMIN USER
export const userAdminData = user => {
	const data = {
		firstName: user.firstName,
		lastName: user.lastName,
		email: user.email,
		phoneNumber: user.phoneNumber,
		isAdmin: true,
		canEditWorkLog: true,
		isInspector: true,
		canEditReport: true,
		canDeleteTask: true
	}
	return data
}

//WorkingAddress = job

export const workInAddressData = workInAddress => {
	const data = {
		address: workInAddress.address,
		city: workInAddress.city,
		inspectionDate: workInAddress.inspectionDate,
		jobDescription: workInAddress.jobDescription,
		measurementDescription: workInAddress.measurementDescription,
		inspectors: workInAddress.inspectors,
		inspectionSignature: workInAddress.inspectionSignature,
		housingCooperative: workInAddress.housingCooperative,
		toBeBilled: workInAddress.billingName,
		orderer: workInAddress.orderer,
		invoicingInstructions: workInAddress.invoicingInstructions
	}

	return data
}

export const workEventData = workEvent => {
	const data = {
		date: moment(workEvent.date),
		description: workEvent.description,
		hoursWorked: workEvent.hoursWorked
	}
	return data
}

export const workingAttributeData = workingAttribute => {
	const data = {
		name: workingAttribute.name,
		unit: workingAttribute.unit,
		value: workingAttribute.value
	}
	return data
}

export const workingAttributeValueData = workingAttributeValue => {
	const data = {
		value: workingAttributeValue.value
	}
	return data
}

//..not yet modded..//

// WORKLOG ACTION
export const dataForWorkLog = (workLog, workEvent, apartment) => {
	const date = moment(workLog.date, 'l')
	const data = {
		id: apartment.workLog.id,
		date: date,
		workInApartmentId: apartment.id,
		workHours: {
			hoursWorked: workLog.hoursWorked
		},
		cityCharge: {
			amount: workLog.cityChargeAmount,
			date: workLog.cityChargeDate
		},
		workEvents: workEvent
	}
	return data
}

// JOB ACTION
// todo: is it obsolete?
export const dataAddingNewJob = (jobState, apartments) => {
	const data = {
		address: jobState.job.address,
		city: jobState.job.town,
		housingCooperative: jobState.job.housingCo,
		roles: jobState.roles,
		//landlord
		estateManager: {
			name: jobState.job.landLord,
			phoneNumber: jobState.job.landLordPhone,
			persons: jobState.landLordContacts
		},
		maintenanceCompany: {
			name: jobState.job.propertyMaintenance,
			phoneNumber: jobState.job.propertyMaintenancePhone,
			email: jobState.job.propertyMaintenanceEmail,
			address: jobState.job.propertyMaintenanceAddress,
			city: jobState.job.propertyMaintenanceCity,
			person: jobState.propertyMaintenanceContact
		},
		toBeBilled: jobState.job.toBeBilled,
		workInApartments: apartments
	}

	return data
}
