import { MaterialType } from '../models/materialType'
import { GetAction } from './action'
import { materialTypes as types } from './types'

export const get = (): GetAction<types> => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/default/material_type'
			}
		}
	}
}

export const create = (materialType: MaterialType) => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/default/material_type',
				data: materialType
			}
		}
	}
}

export const update = (updateData: Partial<MaterialType>, id: number) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/material_type/' + id,
				data: updateData
			}
		}
	}
}
