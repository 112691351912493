/**
 * WorkLog action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { workLogTypes as types } from './types'
import { dataForWorkLog } from './utils'

export const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

export const postWorkLog = (workLog, workEvent, apartment) => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/worklog',
				data: dataForWorkLog(workLog, workEvent, apartment)
			}
		}
	}
}

export const resetStates = () => {
	return {
		type: types.resetState
	}
}
