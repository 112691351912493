/**
 * Reducer for city charge
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { cityChargeTypes as types, loginTypes } from '../actions/types'

const initialState = {
	newCityCharge: '',
	loading: false,
	error: ''
}

export const cityChargeReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.create:
			return {
				...state,
				loading: true
			}
		case types.createSuccess:
			return {
				...state,
				newCityCharge: action.payload.data,
				loading: false
			}
		case types.createFail:
			return {
				...state,
				loading: false
			}

		case loginTypes.logout:
			return initialState

		default:
			return state
	}
}

export const cityChargeSelectors = {
	getField: (state = initialState, field) => state[field],
	getNewCityCharge: (state = initialState) => state.newCityCharge
}
