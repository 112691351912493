/**
 * working attribute value action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { workingAttributeValueTypes as types } from './types'
import { workingAttributeValueData } from './utils'

export const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

export const resetStates = () => {
	return {
		type: types.resetState
	}
}

// Server side actions:

export const getAll = () => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/default/working_attribute_value'
			}
		}
	}
}

export const create = (workingAttributeValue, workEventId) => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/working_attribute_value/' + workEventId,
				data: workingAttributeValue
			}
		}
	}
}

export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/default/working_attribute_value/' + id
			}
		}
	}
}

export const update = (updateData, id) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/working_attribute_value/' + id,
				data: updateData
			}
		}
	}
}

export const deleteWorkingAttributeValue = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/working_attribute_value/' + id
			}
		}
	}
}

export const updateRelation = (updateData, id) => {
	// WorkEvent, WorkingAttribute
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/working_attribute_value/' + id,
				data: updateData
			}
		}
	}
}

export const deleteWorkingAttributeValueRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/working_attribute_value/' + id,
				data: deleteData
			}
		}
	}
}
