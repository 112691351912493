/**
 * CityCharge action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2020
 */

import { cityChargeTypes as types } from './types'
import { cityChargeData } from './utils'

export const get = () => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/default/city_charge'
			}
		}
	}
}

export const create = data => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/default/city_charge',
				data
			}
		}
	}
}

export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/default/city_charge/' + id
			}
		}
	}
}

export const update = (updateData, id) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/city_charge/' + id,
				data: updateData
			}
		}
	}
}

export const deleteCityCharge = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/city_charge/' + id
			}
		}
	}
}

export const updateRelation = (updateData, id) => {
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/city_charge/' + id,
				data: updateData
			}
		}
	}
}

export const deleteCityChargeRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/city_charge/' + id,
				data: deleteData
			}
		}
	}
}
