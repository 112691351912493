/**
 * Job reducer
 *
 * @author Elsa <elsa@vertics.co>
 *
 * To update apartments and job details when WorkInAddress has allready created.
 * @copyright Vertics Co 2019
 */
import {
	jobTypeTypes as types,
	materialTypes,
	loginTypes,
	apartmentTypes,
	workInAddressTypes,
	roomTypes
} from '../actions/types'

const initialState = {
	job: null,
	vtFile: {},
	jobTypes: [],
	workLog: {},
	workLogByRooms: [],
	apartment: {},
	rooms: [],
	apartments: [],
	material: [],
	devices: [],
	dryers: [],
	loading: false,
	error: '',
	previewApartment: {},
	surfaces: [],
	dryStart: null,
	dryEnd: null
}

export const editingJobReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.changeField:
			return {
				...state,
				job: {
					...state.job,
					[action.name]: action.value
				}
			}
		case types.changeFieldValueOfAttributes:
			const modifiedArray = [...state.valueOfAttributes]
			modifiedArray[action.index] = {
				...modifiedArray[action.index],
				[action.name]: action.value
			}
			return {
				...state,
				valueOfAttributes: modifiedArray
			}
		case workInAddressTypes.getByIdSuccess:
			return {
				...state,
				job: action.payload.data,
				apartments: action.payload.data.workInApartments
			}
		case workInAddressTypes.setCurrentAddress:
			return {
				...state,
				job: action.address,
				apartments: action.address.workInApartments
			}
		case workInAddressTypes.updateReducerForFinishEditing: {
			return {
				...state,
				job: initialState.job
			}
		}
		//To set apartment with workLogs to the object
		case apartmentTypes.get:
			const apartmentObject = state.apartments.filter(
				apartment => apartment.id === action.apartmentId
			)
			return {
				...state,
				apartment: apartmentObject[0]
			}
		case apartmentTypes.updatePreviewApartment:
			const { data } = action
			return { ...state, previewApartment: { ...data } }
		case apartmentTypes.getByIdSuccess:
			const modifiedApartments = state.apartments.map(apartment =>
				apartment.id === action.payload.data.id
					? Object.assign(apartment, action.payload.data)
					: apartment
			)

			const updatedCurrentApartment = state.apartment
				? modifiedApartments.find(a => a.id === state.apartment.id)
				: null

			return {
				...state,
				workLog: action.payload.data.workEvents,
				//rooms: action.payload.data.rooms,
				apartments: modifiedApartments,
				apartment: updatedCurrentApartment
					? updatedCurrentApartment
					: state.apartment
			}
		case apartmentTypes.getDetailByIdSuccess: {
			return {
				...state,
				workLogByRooms: [...action.payload.data.rooms],
				dryStart: action.payload.data.dryStart,
				dryEnd: action.payload.data.dryEnd
			}
		}
		case apartmentTypes.resetWorkLog:
			return {
				...state,
				workLog: []
			}
		case apartmentTypes.getWorkEventByApartmentIdSuccess:
			return {
				...state,
				previewApartment: { ...state.previewApartment, ...action.payload.data }
			}
		case types.getSuccess:
			return {
				...state,
				jobTypes: action.payload.data
			}
		case materialTypes.getSuccess:
			return { ...state, material: [...action.payload.data] }

		case types.getMachineTypeSuccess:
			const res = action.payload.data
			const deviceList = res.filter(machine => machine.category === 'device')
			const dryerList = res.filter(machine => machine.category === 'dryer')
			return { ...state, dryers: [...dryerList], devices: [...deviceList] }

		case apartmentTypes.addRoom:
			return {
				...state,
				rooms: action.room
			}

		case roomTypes.clearRooms:
			return {
				...state,
				rooms: []
			}

		case types.deleteMachineSuccess:
			const rooms = state.workLogByRooms

			for (let index = 0; index < rooms.length; index++) {
				rooms[index].machineInstallments = rooms[
					index
				].machineInstallments.filter(
					m => m.id !== action.payload.data.machineId
				)
			}

			return {
				...state,
				workLogByRooms: rooms
			}

		case loginTypes.logout:
			return {
				...initialState
			}

		case types.resetState:
			return {
				...state,
				workLog: initialState.workLog
			}

		default:
			return state
	}
}

export const editingJobSelectors = {
	getField: (state = initialState, field) => state[field],
	getApartment: (state = initialState) => state.apartment,
	getApartments: (state = initialState) => state.apartments,
	getWorkLog: (state = initialState) => state.workLog,
	getJob: (state = initialState) => state.job,
	getJobTypes: (state = initialState) => state.jobTypes,
	getVT: (state = initialState) => state.vtFile,
	getError: (state = initialState) => state.error,
	getPreviewApartment: (state = initialState) => state.previewApartment
}
