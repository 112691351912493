/**
 * Room reducer
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	roomTypes as types,
	apartmentTypes,
	loginTypes
} from '../actions/types'

const initialState = {
	rooms: [],
	roomsForWorkEvent: [],
	loading: false
}

export const roomReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.updateRelationSuccess:
			return {
				...state,
				rooms: [...state.rooms, action.payload.data]
			}

		case types.deleteRelation:
			return {
				...state,
				loading: true
			}
		case types.deleteRelationFail:
			return {
				...state,
				loading: false
			}
		case types.deleteFail:
			return {
				...state,
				loading: false
			}

		case apartmentTypes.getDetailByIdSuccess:
		case apartmentTypes.getByIdSuccess:
			return {
				...state,
				rooms: action.payload.data.rooms,
				loading: false
			}

		case types.addRoom:
			return {
				...state,
				roomsForWorkEvent: action.room
			}

		case loginTypes.logout:
			return {
				...initialState
			}
		case types.resetState:
			return { ...state, roomsForWorkEvent: initialState.roomsForWorkEvent }

		default:
			return state
	}
}

export const roomSelectors = {
	getField: (state = initialState, field) => state[field]
}
