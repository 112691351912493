import { Parameter } from '../models/parameter'
import { GetAction } from './action'
import { parameterTypes as types } from './types'

export const get = (): GetAction<types> => {
	return {
		type: types.getAll,
		payload: {
			request: {
				method: 'GET',
				url: '/default/parameter'
			}
		}
	}
}

export const update = (updateData: Partial<Parameter>, id: number) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/parameter/' + id,
				data: updateData
			}
		}
	}
}
