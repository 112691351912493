import { loadingTypes as types } from '../actions/types'

const initialState = {
	loading: false
}

export const loadingReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.setLoading:
			return {
				...state,
				loading: true
			}
		case types.stopLoading:
			return {
				...state,
				loading: false
			}
		default:
			return state
	}
}

export const loadingSelectors = {
	getLoading: (state = initialState) => state.loading
}
