/**
 * workingAttributeValue reducer
 * Adding values when making workEvent
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	workingAttributeValueTypes as types,
	apartmentTypes,
	loginTypes
} from '../actions/types'

const initialState = {
	workingAttributeValues: [],
	workingAttributeValuesId: [],
	loading: false
}

export const workingAttributeValueReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.updateRelationSuccess:
			return {
				...state,
				workingAttributeValues: [
					...state.workingAttributeValues,
					action.payload.data
				],
				workingAttributeValuesId: [
					...state.workingAttributeValuesId,
					action.payload.data.id
				]
			}

		case types.deleteRelation:
			return {
				...state,
				loading: true
			}
		case types.deleteRelationFail:
			return {
				...state,
				loading: false
			}
		case types.deleteFail:
			return {
				...state,
				loading: false
			}

		case apartmentTypes.getByIdSuccess:
			return {
				...state,
				workingAttributeValues: action.payload.data.workingAttributeValues,
				loading: false
			}

		case loginTypes.logout:
			return {
				...initialState
			}

		default:
			return state
	}
}

export const workingAttributeValueSelectors = {
	getField: (state = initialState, field) => state[field]
}
