/**
 * Reducer adding values to workInAddress
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { workInAddressTypes as types, loginTypes } from '../actions/types'

const initialState = {
	address: '',
	city: '',
	housingCooperative: '',
	billingName: '',
	orderer: '',
	createdJob: '',
	workInAddresses: [],
	error: '',
	workInApartments: [],
	newApartmentName: '',
	id: null,
	inspectionDate: null,
	inspectors: '',
	jobDescription: '',
	measurementDescription: '',
	invoicingInstructions: ''
}

export const workInAddressReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.changeField:
			return {
				...state,
				[action.name]: action.value
			}
		case types.changeNewApartmentName:
			return {
				...state,
				newApartmentName: action.name
			}
		case types.changeFieldApartment:
			const modifiedArray = [...state.apartments]
			modifiedArray[action.index] = {
				...modifiedArray[action.index],
				[action.name]: action.value
			}
			return {
				...state,
				apartments: modifiedArray
			}
		case types.updateReducerForEditing:
			return {
				...state,
				...action.data
			}
		case types.create:
			return {
				...state
			}

		case types.createSuccess:
			return {
				...state,
				createdJob: action.payload.data.id
			}

		case types.createFail:
			return {
				...state,
				error: action.error
			}

		case types.getSuccess:
			return {
				workInAddresses: action.payload.data
			}

		case types.resetState:
			return initialState

		case loginTypes.logout:
			return initialState

		default:
			return state
	}
}

export const workInAddressSelectors = {
	getField: (state = initialState, field) => state[field],
	getWorkInAddress: (state = initialState) => state,
	getCreatedWorkInAddress: (state = initialState) => state.createdJob,
	getAll: (state = initialState) => state.workInAddresses
}
