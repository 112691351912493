/**
 * Reusable icon component
 *
 * @author Elsa <elsa@vertics.co>
 *
 */
import React, { FC } from 'react'

interface IconProps {
	iconName: string
	text?: string
	outlined?: boolean
	onClick?: (event) => void
	className?: string
	active?: boolean
	disabled?: boolean
	[x: string]: any
}

const Icon: FC<IconProps> = props => {
	const {
		iconName,
		text,
		outlined,
		onClick,
		className,
		active,
		disabled,
		...rest
	} = props

	const iconClass: string[] = []
	if (className) {
		iconClass.push(className)
	}
	if (active) {
		iconClass.push('active')
	}
	if (disabled) {
		iconClass.push('disabled')
	}

	if (outlined)
		return (
			<div className={`outlined ${iconClass.join(' ')}`} onClick={onClick}>
				{text && <p className="icon-text-outlined">{text}</p>}
				<i className={`material-icons ${iconName}`}>{iconName}_outline</i>
			</div>
		)

	return (
		<div
			className={`icon-container ${iconClass.join(' ')}`}
			onClick={onClick}
			{...rest}
		>
			<i className={`material-icons ${iconName}`} {...rest}>
				{iconName}
			</i>
			{text && <p className="icon-text">{text}</p>}
		</div>
	)
}

export default Icon
