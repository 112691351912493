/**
 * A Reusable wrapper component
 * Define authentication state and pass it to its children
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'

class AuthCheck extends Component {
	render() {
		let userId = localStorage.getItem('bearer')
		const isAuthenticated = userId ? true : false

		return this.props.children({ isAuthenticated })
	}
}

export default AuthCheck
