/**
 * Protected route
 * Only let user access the route if user has been authenticated
 * Otherwise redirect user to login page
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthChecker from './AuthChecker'
import { Helmet } from 'react-helmet'

// Wrap title and meta data to Component
const withHelmet = (component, title) => (
	<>
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={`Vertics boilerplate | ${title}`} />
		</Helmet>
		{component}
	</>
)

const ProtectedRoute = ({ component: Component, title, ...rest }) => {
	return (
		<AuthChecker>
			{({ isAuthenticated }) => (
				<Route
					{...rest}
					render={props =>
						isAuthenticated ? (
							withHelmet(<Component {...props} />, title)
						) : (
							<Redirect to="/kirjautuminen" />
						)
					}
				/>
			)}
		</AuthChecker>
	)
}

export default ProtectedRoute
