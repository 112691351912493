/**
 * Reducer adding values to company
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	companyTypes as types,
	loginTypes,
	workInAddressTypes
} from '../actions/types'

const initialState = {
	companies: [],
	companiesId: [],
	idToRemove: ''
}

export const companiesReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.create:
			return state

		case types.createSuccess:
			return {
				...state,
				companies: [...state.companies, action.payload.data],
				companiesId: [...state.companiesId, action.payload.data.id]
			}
		case types.updateRelationSuccess:
			const updatedCompanies = state.companies
			const index = updatedCompanies.findIndex(
				company => company.id === action.payload.data.id
			)
			updatedCompanies[index] = action.payload.data
			return {
				...state,
				companies: updatedCompanies
			}

		case types.delete:
			return {
				...state,
				idToRemove: action.id
			}

		case types.deleteSuccess:
			const modifiedCompanies = [...state.companies].filter(
				company => company.id !== state.idToRemove
			)
			const modifiedCompaniesId = [...state.companiesId].filter(
				companyId => companyId !== state.idToRemove
			)
			return {
				...state,
				companies: modifiedCompanies,
				companiesId: modifiedCompaniesId,
				idToRemove: ''
			}

		case workInAddressTypes.createSuccess:
			return initialState

		case loginTypes.logout:
			return initialState

		default:
			return state
	}
}

export const companiesSelectors = {
	getCompanies: (state = initialState) => state.companies,
	getCompaniesId: (state = initialState) => state.companiesId
}
