import { persistor } from '../store'

const USER_LOGIN_KEY = 'login_timestamp'
const EXPIRATION_TTL_DAYS = 30
const MS_PER_DAY = 1000 * 60 * 60 * 24

const get = (key: string): string => {
	const value = window.localStorage.getItem(key)
	return value || ''
}

const set = (key: string, value: string): void => {
	try {
		window.localStorage.setItem(key, value)
	} catch (error) {
		console.error('unable to set local storage value', error)
	}
}

const differenceInDays = (timestamp: string, now: Date): number => {
	if (timestamp === '') return 0

	try {
		const loginDate = new Date(Date.parse(timestamp))
		const diffTime = Math.abs(now.valueOf() - loginDate.valueOf())
		return Math.ceil(diffTime / MS_PER_DAY)
	} catch (error) {
		return -1
	}
}

export const setLoggedIn = (): void => {
	set(USER_LOGIN_KEY, new Date().toISOString())
}

export const isExpired = (): boolean => {
	const timestamp = get(USER_LOGIN_KEY)
	const diffDays = differenceInDays(timestamp, new Date())
	const isEmpty = timestamp === ''
	const isExpired = diffDays > EXPIRATION_TTL_DAYS

	return isEmpty || isExpired
}

export const purgeExpired = async (): Promise<any> => {
	try {
		if (isExpired()) {
			await persistor.purge()
		}
	} catch (error) {
		console.error('Error clearing redux local storage state', error)
	}
}

const userExpiration = {
	isExpired,
	setLoggedIn,
	purgeExpired
}

export default userExpiration
