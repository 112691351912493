import { workEventTypes as types } from '../actions/types'
import { WorkingAttributeValue } from '../models/workingAttributeValue'

export type AttributeValue = {
	workingAttribute: number
	workingAttributeValue: any
	subJobTypeIds: number[]
}

type workEventValuesState = {
	attributeValues: AttributeValue[]
}

const initialState: workEventValuesState = { attributeValues: [] }

export const workEventValuesReducer = (
	state = initialState,
	action
): workEventValuesState => {
	switch (action.type) {
		case types.addSubJobType: {
			const subType = action.subJob
			const subTypeId = subType.id
			let attributes = state.attributeValues
			const temporaryAttributes = [
				...subType.workingAttributes.filter(attr => attr.unit != 'kohde')
			]
			temporaryAttributes.forEach(attribute => {
				const foundAttribute = attributes.find(
					x => x.workingAttribute == attribute.id
				)
				if (!foundAttribute) {
					attributes = [
						...attributes,
						{
							workingAttributeValue: action.isEdit ? attribute.value : '',
							workingAttribute: attribute.id,
							subJobTypeIds: [subTypeId]
						} as AttributeValue
					]
				} else {
					const foundSubJobType = foundAttribute.subJobTypeIds.find(
						id => subTypeId
					)
					if (!foundSubJobType) {
						foundAttribute.subJobTypeIds.push(subTypeId)
					}
				}
			})

			return {
				...state,
				attributeValues: attributes
			} as workEventValuesState
		}

		case types.removeSubJob: {
			const id = action.subJob.id
			const newAttributes: AttributeValue[] = []
			state.attributeValues.forEach(attribute => {
				if (attribute.subJobTypeIds.find(subJobTypeId => subJobTypeId != id)) {
					if (
						attribute.subJobTypeIds.find(subJobTypeId => subJobTypeId == id)
					) {
						attribute.subJobTypeIds = attribute.subJobTypeIds.filter(
							subJobTypeId => subJobTypeId != id
						)
					}
					newAttributes.push(attribute)
				}
			})
			return {
				...state,
				attributeValues: newAttributes
			} as workEventValuesState
		}

		case types.changeFieldAttributeValue:
			let clonedAttributes = [...state.attributeValues]
			const modifiedAttributes = clonedAttributes.map(attribute => {
				if (attribute.workingAttribute === action.name) {
					return {
						workingAttributeValue: action.value,
						workingAttribute: action.name,
						subJobTypeIds: attribute.subJobTypeIds
					}
				} else {
					return attribute
				}
			})

			return {
				...state,
				attributeValues: modifiedAttributes
			} as workEventValuesState

		case types.resetState:
			return initialState
		default:
			return state
	}
}

export const workEventValuesSelectors = {
	getField: (state = initialState, field: string) => state[field],
	getId: (state = initialState, id: number) => state[id]
}
