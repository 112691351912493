import { reportTypes as types } from './types'
import { GetAction, GetRequest, SaveAction, SaveRequest } from './action'
import { JobSpecificationReport } from '../models/jobSpecificationReport'

export const inspection = (id: number, preview: boolean): GetAction<types> => {
	let request: GetRequest = {
		method: 'GET',
		url: `/inspection/${id}?preview=${preview}`
	}
	if (preview) {
		request.responseType = 'arraybuffer'
	}
	return {
		type: types.inspectionReport,
		payload: {
			client: 'reporting',
			request
		}
	}
}

export const measurement = (
	preview: boolean,
	data: any
): SaveAction<types, any> => {
	let request: SaveRequest<any> = {
		method: 'PUT',
		url: `/measurement?preview=${preview}`,
		data
	}
	if (preview) {
		request.responseType = 'arraybuffer'
	}
	return {
		type: types.measurementReport,
		payload: {
			client: 'reporting',
			request
		}
	}
}

export const jobSpecification = (
	preview: boolean,
	showTaxes: boolean,
	showMaterials: boolean,
	showZeroHours: boolean,
	data: any
): SaveAction<types, any> => {
	let request: SaveRequest<any> = {
		method: 'PUT',
		url: `/jobSpecification?preview=${preview}&showTaxes=${showTaxes}&showMaterials=${showMaterials}&showZeroHours=${showZeroHours}`,
		data
	}
	if (preview) {
		request.responseType = 'arraybuffer'
	}
	return {
		type: types.jobSpecificationReport,
		payload: {
			client: 'reporting',
			request
		}
	}
}

export const JobSpecificationOverview = (
	apartmentId: number
): GetAction<JobSpecificationReport> => {
	let request: GetRequest = {
		method: 'GET',
		url: `/report/job_spec_overview/${apartmentId}`
	}

	return {
		type: types.jobSpecificationReportOverview,
		payload: {
			request
		}
	}
}

export const JobSpecificationDetails = (
	reportId: number
): GetAction<JobSpecificationReport> => {
	let request: GetRequest = {
		method: 'GET',
		url: `/report/job_spec_details/${reportId}`
	}

	return {
		type: types.jobSpecificationReportDetails,
		payload: {
			request
		}
	}
}
