/**
 * Job reducer
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	apartmentTypes as types,
	jobTypes,
	workInAddressTypes
} from '../actions/types'

const initialState = {
	//to create new object when creating new WorkInAddress
	apartment: {
		apartmentNumber: '',
		residentName: '',
		residentPhoneNumber: '',
		residentEmail: ''
	},
	apartments: [],
	deleteId: null,
	loading: false,
	error: ''
}

export const apartmentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.changeFieldApartment:
			return {
				...state,
				apartment: {
					...state.apartment,
					[action.name]: action.value
				},
				error: initialState.error
			}
		case types.changeFieldApartments:
			return {
				...state,
				[action.name]: action.value,

				error: initialState.error
			}
		case workInAddressTypes.updateReducerForEditing: {
			return {
				...state,
				apartments: [...action.data.workInApartments]
			}
		}
		case workInAddressTypes.updateReducerForFinishEditing: {
			return {
				...state,
				apartments: [...initialState.apartments]
			}
		}
		case types.changeFieldApartmentsArray:
			const modifiedArray = [...state.apartments]
			modifiedArray[action.index] = {
				...modifiedArray[action.index],
				[action.name]: action.value
			}
			return {
				...state,
				apartments: modifiedArray,
				error: initialState.error
			}

		case types.add:
			return {
				...state,
				apartments: [...state.apartments, action.payload.data],
				loading: false,
				apartment: initialState.apartment
			}

		case types.delete:
			return {
				...state,
				apartments: [
					...state.apartments.filter(
						apartment =>
							apartment.apartmentNumber.toLowerCase() !==
							action.data.toLowerCase()
					)
				]
			}
		case types.setApartment:
			const modifiedApartments = [...state.apartments]
			modifiedApartments[action.payload.index] = action.payload.data
			return {
				...state,
				apartments: modifiedApartments
			}
		case types.set:
			let id = action.payload.data
			let i = action.payload.index
			const setApartment = state.apartments.find(
				apartment => apartment.id === id
			)
			return {
				...state,
				apartment: { ...setApartment, index: i }
			}
		case types.unset:
			return {
				...state,
				apartment: initialState.apartment
			}
		case jobTypes.getJobByIdSuccess:
			return {
				...state,
				apartments: action.payload.data.workInApartments
			}

		case types.update:
			return {
				...state,
				loading: true
			}
		case types.reset:
			return initialState
		case types.updateSuccess:
			const updatedApartments = [...state.apartments]
			const index = updatedApartments.findIndex(apartment => {
				return apartment.id === action.payload.data.id
			})
			updatedApartments[index] = action.payload.data
			return {
				...state,
				apartments: updatedApartments,
				loading: false
			}
		case types.updateFail:
			return {
				...state,
				loading: false
			}
		case types.create:
			return {
				...state,
				loading: true
			}
		case types.createSuccess:
			return {
				...state,
				apartments: [...state.apartments, action.payload.data],
				loading: false
			}
		case types.createFail:
			return {
				...state,
				loading: false
			}

		case types.setDeleteId:
			return {
				...state,
				deleteId: action.id
			}

		case types.remove:
			return {
				...state,
				loading: true
			}
		case types.removeSuccess:
			return {
				...state,
				apartments: [
					...state.apartments.filter(
						apartment => apartment.id !== state.deleteId
					)
				],
				loading: false
			}
		case types.removeFail:
			return {
				...state,
				loading: false
			}

		case jobTypes.initializeNewJobStates:
			return initialState
		default:
			return state
	}
}

export const apartmentSelectors = {
	getField: (state = initialState, field) => state[field],
	getApartment: (state = initialState) => state.apartment,
	getApartments: (state = initialState) => state.apartments,
	getError: (state = initialState) => state.error
}
