/**
 * Apartment action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { apartmentTypes as types } from './types'
import { apartmentData } from './utils'

export const changeFieldApartment = (name, value) => {
	return {
		type: types.changeFieldApartment,
		name,
		value
	}
}

export const changeFieldApartments = (name, value) => {
	return {
		type: types.changeFieldApartments,
		name,
		value
	}
}
export const changeFieldApartmentsArray = (name, value, index) => {
	return {
		type: types.changeFieldApartmentsArray,
		name,
		value,
		index
	}
}

export const addApartment = apartment => {
	return {
		type: types.add,
		payload: {
			data: apartment
		}
	}
}

export const deleteApartment = apartment => {
	return {
		type: types.delete,
		data: apartment.apartmentNumber
	}
}

export const setApartment = (apartmentId, i) => {
	return {
		type: types.set,
		payload: {
			data: apartmentId,
			index: i
		}
	}
}

export const unsetApartment = () => {
	return {
		type: types.unset
	}
}

export const setDeleteId = id => {
	return {
		type: types.setDeleteId,
		id
	}
}

export const resetWorkLog = () => {
	return {
		type: types.resetWorkLog
	}
}

export const resetApartment = () => {
	return {
		type: types.reset
	}
}

export const getApartmentFromJobs = apartmentId => {
	return {
		type: types.get,
		apartmentId
	}
}

export const addRoom = room => {
	return {
		type: types.addRoom,
		room
	}
}

export const updatePreviewApartment = data => {
	return {
		type: types.updatePreviewApartment,
		data
	}
}

// SERVER SIDE ACTIONS

export const getWorkLogById = worklogId => {
	return {
		type: types.getWorkLogs,
		payload: {
			request: {
				method: 'GET',
				url: `/workinapartment/worklogs/${worklogId}`
			}
		}
	}
}

// FIXED ACTIONS

export const getAll = () => {
	return {
		type: types.getAll,
		payload: {
			request: {
				method: 'GET',
				url: '/default/work_in_apartment'
			}
		}
	}
}

export const create = apartment => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/default/work_in_apartment',
				data: apartmentData(apartment)
			}
		}
	}
}

export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/work_in_apartment/overview/' + id
			}
		}
	}
}

export const getDetailById = id => {
	return {
		type: types.getDetailById,
		payload: {
			request: {
				method: 'GET',
				url: '/work_in_apartment/detail/' + id
			}
		}
	}
}

const generateParam = (fieldName, value) => {
	return `${fieldName}=${value}&`
}

export const getElectricityPayments = (
	paid,
	querySent,
	electricityAmountEntered,
	bankDetailsCompleted
) => {
	let url =
		'/work_in_apartment/electricity?' +
		generateParam('paid', paid) +
		generateParam('querySent', querySent) +
		generateParam('electricityAmountEntered', electricityAmountEntered) +
		generateParam('bankDetailsCompleted', bankDetailsCompleted)

	return {
		type: types.getElectricPayments,
		payload: {
			request: {
				method: 'GET',
				url: url
			}
		}
	}
}

export const update = (updateData, id) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/work_in_apartment/' + id,
				data: updateData
			}
		}
	}
}

export const deleteApartmentFromServer = id => {
	return {
		type: types.remove,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/work_in_apartment/' + id
			}
		},
		id
	}
}

export const updateRelation = (updateData, id) => {
	//workInAdress, File, Comment, Room, WorkEvent, Person
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/work_in_apartment/' + id,
				data: updateData
			}
		}
	}
}

export const deleteApartmentRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/work_in_apartment/' + id,
				data: deleteData
			}
		}
	}
}

export const checkToken = (apartmentId, token) => {
	return {
		type: types.checkToken,
		payload: {
			request: {
				method: 'GET',
				url: `/work_in_apartment/bankaccount/check/${apartmentId}?token=${token}`
			}
		}
	}
}

export const sendBankAccount = (apartmentId, data, token) => {
	return {
		type: types.sendBankAccount,
		payload: {
			request: {
				method: 'PATCH',
				url: `/work_in_apartment/bankaccount/${apartmentId}?token=${token}`,
				data
			}
		}
	}
}

export const sendBankAccountAdmin = (apartmentId, data, token) => {
	return {
		type: types.sendBankAccount,
		payload: {
			request: {
				method: 'PATCH',
				url: `/work_in_apartment/bankaccountAdmin/${apartmentId}`,
				data
			}
		}
	}
}

export const getWorkEventByApartmentId = (id, type) => {
	return {
		type: types.getWorkEventByApartmentId,
		payload: {
			request: {
				method: 'GET',
				url: `/file/report/work_in_apartment/${id}${
					type === undefined ? '' : `?type=${type}`
				}`
			}
		}
	}
}

export const sendElectricityForm = (apartmentId, data) => {
	return {
		type: types.sendElectricityForm,
		payload: {
			request: {
				method: 'POST',
				url: `/work_in_apartment/bankaccount/${apartmentId}`,
				data
			}
		}
	}
}

export const electricityPaymentDone = apartmentIds => {
	return {
		type: types.electricityPaymentDone,
		payload: {
			request: {
				method: 'PATCH',
				url: 'work_in_apartment/electricityPaymentDone',
				data: {
					apartmentIds
				}
			}
		}
	}
}
