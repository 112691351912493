/**
 * Room action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2020
 */

import { roomTypes as types } from './types'
import { roomData } from './utils'

export const resetStates = () => {
	return {
		type: types.resetState
	}
}

export const addRoom = room => {
	return {
		type: types.addRoom,
		room
	}
}

export const clearRooms = () => {
	return {
		type: types.clearRooms
	}
}

export const get = () => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/default/room'
			}
		}
	}
}

export const create = room => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/room',
				data: roomData(room)
			}
		}
	}
}

export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/default/room/' + id
			}
		}
	}
}

export const update = (updateData, id) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/room/' + id,
				data: updateData
			}
		}
	}
}

export const deleteRoom = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/room/' + id
			}
		}
	}
}

export const updateRelation = (updateData, id) => {
	// WorkEvent, Surface, WorkInApartment or machineInstallments
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/room/' + id,
				data: updateData
			}
		}
	}
}

export const deleteRoomRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/room/' + id,
				data: deleteData
			}
		}
	}
}
