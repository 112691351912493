import { Tooltip } from '../models/tooltip'
import { GetAction } from './action'
import { tooltipTypes as types } from './types'

export const get = (): GetAction<types> => {
	return {
		type: types.getAll,
		payload: {
			request: {
				method: 'GET',
				url: '/default/tooltip'
			}
		}
	}
}

export const update = (updateData: Partial<Tooltip>, id: number) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/tooltip/' + id,
				data: updateData
			}
		}
	}
}
