/**
 * Login reducer
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { loginTypes as types } from '../actions/types'

const initialState = {
	user: undefined,
	phone: undefined,
	code: undefined,
	loading: false,
	error: undefined
}

export const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.changeField:
			return {
				...state,
				[action.name]: action.value,
				error: initialState.error
			}

		case types.login:
			return {
				...state,
				loading: true
			}

		case types.loginSuccess:
			localStorage.setItem('bearer', action.payload.data.bearer)
			return {
				...state,
				user: action.payload.data.user,
				loading: false
			}

		case types.loginFail:
			return {
				...state,
				loading: false,
				error: 'Tarkista koodi!'
			}
		case types.logout:
			return {
				...initialState
			}
		case types.sendSMS:
			return {
				...state,
				loading: true
			}

		case types.sendSMSSuccess:
			return {
				...state,
				code: action.payload.data.code,
				loading: false
			}

		case types.sendSMSFail:
			return {
				...state,
				loading: false,
				error: 'Käyttäjää ei löydy.'
			}

		default:
			return state
	}
}

export const loginSelectors = {
	getField: (state = initialState, field) => state[field],
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error,
	getBearer: (state = initialState) => state.bearer,
	getUser: (state = initialState) => state.user,
	getCode: (state = initialState) => state.code
}
