/**
 * Reducer adding values to company
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	companyTypes as types,
	loginTypes,
	workInAddressTypes
} from '../actions/types'

const initialState = {
	name: '',
	phoneNumber: '',
	email: '',
	address: '',
	city: '',
	type: '',
	error: ''
}

export const companyReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.changeField:
			return {
				...state,
				[action.name]: action.value
			}

		case types.create:
			return {
				...state
			}

		case types.createSuccess:
			return initialState

		case types.createFail:
			return {
				...state,
				error: action.error
			}
		case workInAddressTypes.updateReducerForFinishEditing:
		case types.updateRelationSuccess:
			return initialState

		case loginTypes.logout:
			return initialState

		default:
			return state
	}
}

export const companySelectors = {
	getField: (state = initialState, field) => state[field],
	getCompany: (state = initialState) => state
}
