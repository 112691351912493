/**
 * Work In Address action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2020
 */

import { workInAddressTypes as types } from './types'
import { workInAddressData } from './utils'

export const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

export const resetJobState = () => {
	return {
		type: types.resetStates
	}
}

export const setCurrentAddress = address => {
	return {
		type: types.setCurrentAddress,
		address
	}
}

export const updateReducerForEditing = data => {
	return {
		type: types.updateReducerForEditing,
		data
	}
}

export const updateReducerForFinishEditing = () => {
	return {
		type: types.updateReducerForFinishEditing
	}
}

export const changeFieldApartment = (name, value, index) => {
	return {
		type: types.changeFieldApartment,
		name,
		value,
		index
	}
}

export const changeNewApartmentName = name => {
	return {
		type: types.changeNewApartmentName,
		name
	}
}

export const getAll = () => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/default/work_in_address'
			}
		}
	}
}

export const getAllBySearch = params => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/work_in_address',
				params
			}
		}
	}
}

export const create = workInAddress => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/work_in_address',
				data: workInAddressData(workInAddress)
			}
		}
	}
}

export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/work_in_address/' + id
			}
		}
	}
}

export const getOverviewById = id => {
	return {
		type: types.getOverviewById,
		payload: {
			request: {
				method: 'GET',
				url: '/work_in_address/overview/' + id
			}
		}
	}
}

export const update = (id, updateData) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/work_in_address/' + id,
				data: updateData
			}
		}
	}
}

export const updateStatus = (id, status) => {
	return {
		type: types.updateStatus,
		payload: {
			request: {
				method: 'PATCH',
				url: `/work_in_address/${id}/update_status/${status}`
			}
		}
	}
}

export const deleteWorkInAddress = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/work_in_address/' + id
			}
		}
	}
}

export const updateRelation = (updateData, id) => {
	// Roles, WorkInApartments, Files, Companies, Comments
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/work_in_address/' + id,
				data: updateData
			}
		}
	}
}

export const deleteWorkInAddressRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/work_in_address/' + id,
				data: deleteData
			}
		}
	}
}

export const updateCompanyLink = (
	workInAddressId,
	linkType,
	companyId,
	linkName
) => {
	return {
		type: types.updateCompanyLink,
		payload: {
			request: {
				method: 'PATCH',
				url: `/work_in_address/${workInAddressId}/company_link/${linkType}`,
				data: {
					companyId,
					linkName
				}
			}
		}
	}
}

export const deleteCompanyLink = (workInAddressId, linkType, companyId) => {
	return {
		type: types.deleteCompanyLink,
		payload: {
			request: {
				method: 'DELETE',
				url: `/work_in_address/${workInAddressId}/company_link/${linkType}`,
				data: {
					companyId
				}
			}
		}
	}
}
