import { Role } from '../models/role'
import { roleTypes as types } from './types'
import { GetAction, SaveAction, DeleteAction } from './action'

export const roleData = (role: Partial<Role>): Partial<Role> => {
	const data = {
		label: role.label,
		roleTypeId: role.roleTypeId,
		personId: role.personId,
		user: role.userId,
		linkType: role.linkType,
		workInAddressId: role.workInAddressId,
		inspectionPresence: role.inspectionPresence
	}
	return data
}

export const get = (): GetAction<types> => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/default/role'
			}
		}
	}
}

export const create = (role: Partial<Role>): SaveAction<types, Role> => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/default/role',
				data: roleData(role)
			}
		}
	}
}

export const getById = (id: number): GetAction<types> => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/default/role/' + id
			}
		}
	}
}

export const update = (
	updateData: Partial<Role>,
	id: number
): SaveAction<types, Role> => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/role/' + id,
				data: updateData
			}
		}
	}
}

export const deleteRole = (id: number): DeleteAction<types> => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/role/' + id
			}
		},
		id
	}
}

export const updateRelation = (
	updateData: Partial<Role>,
	id: number
): SaveAction<types, Role> => {
	// User or WorkInAddresses
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/role/' + id,
				data: updateData
			}
		}
	}
}

export const deleteRoleRelation = (
	deleteData: Partial<Role>,
	id: number
): SaveAction<types, Role> => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/role/' + id,
				data: deleteData
			}
		}
	}
}
