/**
 * Reducer showing one JobType's subJobTypes
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { subJobTypes as types } from '../actions/types'

const initialState = {
	subJobTypes: [],
	loading: false,
	error: ''
}

export const subJobTypeListReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.addSubJobTypesToShow:
			const subJobType = state.subJobTypes.find(t => t.id === action.subJob.id)

			if (subJobType) return state

			return {
				...state,
				subJobTypes: [...state.subJobTypes, action.subJob]
			}
		case types.removeSubJobTypeToShow:
			return {
				...state,
				subJobTypes: [
					...state.subJobTypes.filter(
						listedType => listedType.id !== action.subJob.id
					)
				]
			}
		case types.resetState:
			return initialState
		default:
			return state
	}
}

export const subJobTypeListSelectors = {
	getSubJobTypes: (state = initialState) => state.subJobTypes
}
