/**
 * Root reducer
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

// Login
import { loginReducer, loginSelectors } from './login'
import { loadingReducer, loadingSelectors } from './loading'
import { jobReducer, jobSelectors } from './job'
import { apartmentsReducer, apartmentSelectors } from './apartments'
import { fileReducer, fileSelectors } from './file'
import { userReducer, userSelectors } from './user'
import { notificationReducer, notificationSelectors } from './notification'
import { editingJobReducer, editingJobSelectors } from './editingJob'
import { cityChargeReducer, cityChargeSelectors } from './cityCharge'
import { workEventReducer, workEventSelectors } from './workEvent'
import {
	workEventValuesReducer,
	workEventValuesSelectors
} from './workEventValues'
import { workLogReducer, workLogSelectors } from './workLog'
import {
	subJobTypeListReducer,
	subJobTypeListSelectors
} from './subJobTypeList'
import { workInAddressSelectors, workInAddressReducer } from './workInAddress'
import { companyReducer, companySelectors } from './company'
import { companiesReducer, companiesSelectors } from './companies'
import { personReducer, personSelectors } from './person'
import { roleReducer, roleSelectors } from './role'
import { roomReducer, roomSelectors } from './room'
import {
	workingAttributeValueReducer,
	workingAttributeValueSelectors
} from './workingAttributeValue'

import { errorTypes } from '../actions/types'

// error handling reducer
// return error message if there is error returned from action
const errorReducer = (state = null, action) => {
	const { type, error } = action
	if (error && error.response && error.response.data) {
		return error.response.data
	}
	if (error) {
		if (/^@@/.test(action.type)) {
			if (typeof error === 'string') {
				return { message: error }
			} else {
				return { message: `Error: ${action.type}` }
			}
		}
		if (typeof error === 'string') {
			return { message: error }
		} else {
			return { message: 'Jotain meni vikaan.' }
		}
	}
	if (type === 'CLEAR_ERROR_MESSAGE') {
		return null
	}
	if (type === errorTypes.showError) {
		return { message: action.payload.data.message }
	}
	return state
}
// export root reducer
export const rootReducer = combineReducers({
	router: connectRouter(history),
	loading: loadingReducer,
	login: loginReducer,
	user: userReducer,
	job: jobReducer,
	editJob: editingJobReducer,
	apartments: apartmentsReducer,
	error: errorReducer,
	cityCharge: cityChargeReducer,
	notification: notificationReducer,
	subJobTypeList: subJobTypeListReducer,
	workLog: workLogReducer,
	workEvent: workEventReducer,
	workEventValues: workEventValuesReducer,
	files: fileReducer,
	workInAddress: workInAddressReducer,
	company: companyReducer,
	companies: companiesReducer,
	person: personReducer,
	role: roleReducer,
	room: roomReducer,
	workingAttributeValue: workingAttributeValueReducer
})

// export history
export { history }

// export getters/ selectors
export {
	loadingSelectors,
	loginSelectors,
	jobSelectors,
	editingJobSelectors,
	apartmentSelectors,
	fileSelectors,
	userSelectors,
	notificationSelectors,
	cityChargeSelectors,
	subJobTypeListSelectors,
	workLogSelectors,
	workEventSelectors,
	workEventValuesSelectors,
	workInAddressSelectors,
	companySelectors,
	companiesSelectors,
	personSelectors,
	roleSelectors,
	roomSelectors,
	workingAttributeValueSelectors
}
