/**
 * Machine installation action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2020
 */

import { machineTypeTypes as types } from './types'
import { machineTypeData } from './utils'

export const get = (onlyActive = true) => {
	let url = '/default/machine_type'
	if (!onlyActive) {
		url += '?onlyActive=false'
	}
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url
			}
		}
	}
}

export const create = machineType => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/default/machine_type',
				data: machineTypeData(machineType)
			}
		}
	}
}

export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/default/machine_type/' + id
			}
		}
	}
}

export const update = (updateData, id) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/machine_type/' + id,
				data: updateData
			}
		}
	}
}

export const deleteMachineType = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/machine_type/' + id
			}
		}
	}
}

export const updateRelation = (updateData, id) => {
	// Measurement or machineType
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/machine_type/' + id,
				data: updateData
			}
		}
	}
}

export const deleteMachineTypeRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/machine_type/' + id,
				data: deleteData
			}
		}
	}
}
