import { loadingTypes as types } from './types'

export const setLoading = () => {
	return {
		type: types.setLoading
	}
}
export const stopLoading = () => {
	return {
		type: types.stopLoading
	}
}
