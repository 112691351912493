/**
 * Core Layout component
 * Wrap the root component with layout UI components
 * e.g Navigation, Footer, Modal, Alert...
 *
 * @Elsa <elsa@vertics.co>
 *
 */
import React, { Component, lazy } from 'react'
import Nav from './Nav'
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from 'react-router-dom'
import Footer from './Footer'
import humitorLogo from '../../assets/Humitor_logo.svg'
import { routerPath } from '../../constants'
const Login = lazy(() => import('../../pages/Login'))
const ElectricityRefundForm = lazy(() =>
	import('../../pages/ElectricityRefundForm')
)

const CoreLayout = props => {
	return (
		<div className="app-core">
			<header>
				<div>
					<img src={humitorLogo} alt="Logo" />
				</div>
			</header>
			<main>{props.children}</main>
			<footer>
				<Footer />
			</footer>
		</div>
	)
}

const RenderSwitchCore = () => {
	return (
		<CoreLayout>
			<Switch>
				<Route path={routerPath.login} component={Login} />
				<Route
					exact
					path={routerPath.electricityRefundForm}
					component={ElectricityRefundForm}
				/>

				<Redirect to={routerPath.login} />
			</Switch>
		</CoreLayout>
	)
}

export default RenderSwitchCore
