/**
 * Error message component
 * Display error message as a banner
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import Snackbar from '@material-ui/core/Snackbar'
import { history } from 'reducers'

class ErrorMessage extends React.Component {
	state = {
		show: false,
		vertical: 'top',
		horizontal: 'center'
	}

	handleClose = () => {
		this.setState({ show: false })
	}

	componentDidMount() {
		if (this.props.error) {
			if (this.handleExpiredToken()) {
				this.setState({ show: true })
			}
		}
	}

	handleExpiredToken() {
		if (
			this.props.error &&
			this.props.error.name == 'AuthorizationRequiredError'
		) {
			window.location.href = '/kirjautuminen'
			return false
		}
		return true
	}

	componentDidUpdate(prevProps) {
		if (prevProps.error !== this.props.error && this.props.error) {
			if (!this.handleExpiredToken()) {
				return
			}
			this.setState({ show: true })
			window.setTimeout(() => this.setState({ show: false }), 5000)
		}
	}

	render() {
		const { error } = this.props
		const { show } = this.state

		if (!error) {
			return null
		}
		return (
			<div className="error-message-container">
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					key={`${this.state.vertical},${this.state.horizontal}`}
					open={show}
					onClose={this.handleClose}
					ContentProps={{
						'aria-describedby': 'message-id'
					}}
					message={<span id="message-id">{error.message}</span>}
				/>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	error: state.error
})

export default connect(mapStateToProps)(ErrorMessage)
