import { fileTypes as types } from '../actions/types'
const initialState = {
	files: [],
	apartmentId: -1,
	loading: false
}

export const fileReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.getSuccess:
			return {
				...state,
				files: [...action.payload.data]
			}
		case types.getFail:
			return {
				...state,
				loading: false
			}
		case types.postReport:
			return {
				...state,
				loading: true
			}
		case types.postReportSuccess:
			return {
				...state,
				loading: false,
				files: [...state.files, action.payload.data]
			}
		case types.postReportFail:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state
	}
}

export const fileSelectors = {
	getField: (state = initialState, field) => state[field],
	getFiles: (state = initialState) => state.files
}
