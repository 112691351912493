/**
 * Role reducer
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	roleTypes as types,
	loginTypes,
	workInAddressTypes
} from '../actions/types'

const initialState = {
	roles: [],
	rolesId: [],
	idToRemove: ''
}

export const roleReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.createSuccess:
			return {
				...state,
				roles: [...state.roles, action.payload.data],
				rolesId: [...state.rolesId, action.payload.data.id]
			}

		case types.updateRelationSuccess:
			const updatedRole = action.payload.data
			return {
				...state,
				roles: state.roles.map(role => {
					if (role.id !== updatedRole.id) return role
					return updatedRole
				})
			}

		case types.delete:
			return {
				...state,
				idToRemove: action.id
			}

		case types.deleteSuccess:
			const modifiedRoles = state.roles.filter(
				role => role.id !== state.idToRemove
			)
			const modifiedRolesId = state.rolesId.filter(
				roleId => roleId !== state.idToRemove
			)
			return {
				...state,
				roles: modifiedRoles,
				rolesId: modifiedRolesId,
				idToRemove: ''
			}

		case loginTypes.logout:
			return {
				...initialState
			}
		case workInAddressTypes.updateReducerForEditing: {
			return {
				...state,
				roles: [...action.data.roles]
			}
		}
		case workInAddressTypes.updateReducerForFinishEditing: {
			return {
				...state,
				roles: [...initialState.roles]
			}
		}
		default:
			return state
	}
}

export const roleSelectors = {
	getField: (state = initialState, field) => state[field]
}
