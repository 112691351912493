/**
 * Reducer adding values to subJobTypes attributes
 *
 * @author Elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { workLogTypes as types, workEventTypes } from '../actions/types'
import moment from 'moment'

const today = moment()
const formatedToday = moment().format('YYYY-MM-DD')
const initialState = {
	date: formatedToday,
	hoursWorked: '',
	cityChargeAmount: '',
	cityChargeDate: formatedToday,
	newWorkEvent: undefined,
	loading: false,
	error: '',
	momentObj: today
}

export const workEventReducer = (state = initialState, action) => {
	switch (action.type) {
		case workEventTypes.setWorkEvent:
			const workEventMomentObj = moment(action.workEvent.date)
			const formattedWorkEventDate = workEventMomentObj.format('YYYY-MM-DD')

			return {
				...state,
				momentObj: workEventMomentObj,
				date: formattedWorkEventDate,
				hoursWorked: action.workEvent.hoursWorked,
				cityChargeAmount: action.workEvent.cityChargeAmount,
				cityChargeDate: formattedWorkEventDate
			}
		case types.changeField:
			return {
				...state,
				[action.name]: action.value
			}
		case workEventTypes.create:
			return {
				...state,
				loading: true
			}
		case workEventTypes.createSuccess:
			return {
				...state,
				newWorkEvent: action.payload.data,
				loading: false
			}
		case workEventTypes.createFail:
			return {
				...state,
				loading: false
			}
		case workEventTypes.save:
			return {
				...state,
				loading: true
			}
		case workEventTypes.saveSuccess:
			return {
				...state,
				newWorkEvent: action.payload.data,
				loading: false
			}
		case workEventTypes.saveFail:
			return {
				...state,
				loading: false
			}
		case workEventTypes.resetState:
			return initialState
		default:
			return state
	}
}

export const workEventSelectors = {
	getField: (state = initialState, field) => state[field],
	getWorkLog: (state = initialState) => state
}
