/**
 * Main container
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { hot } from 'react-hot-loader/root'

import React, { Component, lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import i18n from './services/i18n'

// Components
import RenderSwitchHome from './shared/layout/HomeLayout'
import RenderSwitchCore from './shared/layout/CoreLayout'
import { userSelectors, loadingSelectors } from './reducers'
import ErrorMessage from './shared/components/UI/ErrorMessage'
import Notification from './shared/components/UI/Notification'

import LoadingCircle from './shared/components/UI/LoadingCircle'

// TODO: remove this with proper components
const Loading = () => <p>Loading...</p>
const LoadingScreen = () => (
	<div className="loading-screen">
		<LoadingCircle color="#2385ec" />
	</div>
)

interface IAppProps {
	loading: boolean
	authenticated: boolean
}

interface IAppState {
	loading?: boolean
	authenticated?: boolean
	showLoading: boolean
}

class App extends Component<IAppProps, IAppState> {
	state = { showLoading: false }
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.loading === false && this.props.loading === true) {
			this.setState({ ...this.state, showLoading: true })
		} else if (prevProps.loading === true && this.props.loading === false) {
			this.setState({ ...this.state, showLoading: false })
		} else if (
			prevProps.loading === true &&
			this.props.loading === true &&
			prevState.showLoading === true
		) {
			setTimeout(() => {
				this.setState({ ...this.state, showLoading: false })
			}, 3000)
		}
	}
	render() {
		return (
			<Suspense fallback={<Loading />}>
				<Helmet titleTemplate="%s - " defaultTitle="Humitor">
					<meta name="description" content="Humitor Oy" />
				</Helmet>
				<Router>
					{this.state.showLoading ? <LoadingScreen /> : null}
					{this.props.authenticated ? (
						<RenderSwitchHome />
					) : (
						<RenderSwitchCore />
					)}
				</Router>
				<ErrorMessage />
				<Notification />
			</Suspense>
		)
	}
}

export default hot(
	connect(state => ({
		authenticated: userSelectors.isAuthenticated(state),
		loading: loadingSelectors.getLoading((state as any).loading)
	}))(App as any)
)
