/**
 * working attribute action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { workingAttributeTypes as types } from './types'
import { workingAttributeData } from './utils'

export const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

export const resetStates = () => {
	return {
		type: types.resetState
	}
}

// Server side actions:

export const getAll = () => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/default/working_attribute'
			}
		}
	}
}

export const getEditable = () => {
	return {
		type: types.getEditable,
		payload: {
			request: {
				method: 'GET',
				url: '/working_attribute/get_editable'
			}
		}
	}
}

export const create = workingAttribute => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/default/working_attribute',
				data: workingAttributeData(workingAttribute)
			}
		}
	}
}

export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/default/working_attribute/' + id
			}
		}
	}
}

export const update = (updateData, id) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/working_attribute/' + id,
				data: updateData
			}
		}
	}
}

export const deleteWorkingAttribute = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/working_attribute/' + id
			}
		}
	}
}

export const updateRelation = (updateData, id) => {
	// SubJobType
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/working_attribute/' + id,
				data: updateData
			}
		}
	}
}

export const deleteWorkingAttributeRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/working_attribute/' + id,
				data: deleteData
			}
		}
	}
}

export const toggleLink = (workingAttributeId, subJobTypeId) => {
	return {
		type: types.toggleLink,
		payload: {
			request: {
				method: 'PATCH',
				url: `/working_attribute/toggle_link/${subJobTypeId}/workingAttribute/${workingAttributeId}`
			}
		}
	}
}
