import { PiecesSubJob } from '../models/piecesSubJob'
import { GetAction } from './action'
import { piecesSubJobTypes as types } from './types'

const entityName = 'pieces_sub_job'

export const get = (): GetAction<types> => {
	return {
		type: types.getAll,
		payload: {
			request: {
				method: 'GET',
				url: `/default/${entityName}`
			}
		}
	}
}

export const getByWorkInApartmentId = (
	workInApartmentId: string
): GetAction<types> => {
	return {
		type: types.getAll,
		payload: {
			request: {
				method: 'GET',
				url: `/default/${entityName}?filterParamName1=workInApartmentId&filterParamValue1=${workInApartmentId}`
			}
		}
	}
}

export const getByWorkEventId = (workEventId: string): GetAction<types> => {
	return {
		type: types.getAll,
		payload: {
			request: {
				method: 'GET',
				url: `/default/${entityName}?filterParamName1=workEventId&filterParamValue1=${workEventId}`
			}
		}
	}
}

export const update = (updateData: Partial<PiecesSubJob>, id: number) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: `/default/${entityName}/${id}`,
				data: updateData
			}
		}
	}
}

export const create = (data: Partial<PiecesSubJob>) => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: `/default/${entityName}`,
				data: data
			}
		}
	}
}
