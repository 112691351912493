/**
 * subJobTypeList action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { subJobTypes as types } from './types'
import { subJobTypeData } from './utils'

export const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

export const addSubJobTypesToShow = subJob => {
	return {
		type: types.addSubJobTypesToShow,
		subJob
	}
}

export const removeSubJobTypeToShow = subJob => {
	return {
		type: types.removeSubJobTypeToShow,
		subJob
	}
}

export const resetStates = () => {
	return {
		type: types.resetState
	}
}

export const get = () => {
	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url: '/default/sub_job_type'
			}
		}
	}
}

export const create = subJobType => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/default/sub_job_type',
				data: subJobTypeData(subJobType)
			}
		}
	}
}

export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/default/sub_job_type/' + id
			}
		}
	}
}

export const update = (updateData, id) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/sub_job_type/' + id,
				data: updateData
			}
		}
	}
}

export const deleteSubJobType = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/sub_job_type/' + id
			}
		}
	}
}

export const updateRelation = (updateData, id) => {
	// JobType or WorkingAttributes
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/sub_job_type/' + id,
				data: updateData
			}
		}
	}
}

export const deleteSubJobTypeRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/sub_job_type/' + id,
				data: deleteData
			}
		}
	}
}
