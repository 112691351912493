/**
 * Company action creator
 *
 * @author elsa <elsa@vertics.co>
 *
 * @copyright Vertics Co 2020
 */

import { companyTypes as types } from './types'
import { companyData } from './utils'

export const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

export const get = (onlyActive = true) => {
	let url = '/default/company'
	if (!onlyActive) {
		url += '?onlyActive=false'
	}

	return {
		type: types.get,
		payload: {
			request: {
				method: 'GET',
				url
			}
		}
	}
}

export const create = company => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: '/default/company',
				data: companyData(company)
			}
		}
	}
}
export const updateCompanyInformation = (id, data) => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/company/' + id,
				data
			}
		}
	}
}
export const getById = id => {
	return {
		type: types.getById,
		payload: {
			request: {
				method: 'GET',
				url: '/default/company/' + id
			}
		}
	}
}

export const update = (updateData, id) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/company/' + id,
				data: updateData
			}
		}
	}
}

export const deleteCompany = id => {
	return {
		type: types.delete,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/company/' + id
			}
		},
		id
	}
}

export const updateRelation = (updateData, id) => {
	//workInAdresses or Person
	return {
		type: types.updateRelation,
		payload: {
			request: {
				method: 'PATCH',
				url: '/default/relation/company/' + id,
				data: updateData
			}
		}
	}
}

export const deleteCompanyRelation = (deleteData, id) => {
	return {
		type: types.deleteRelation,
		payload: {
			request: {
				method: 'DELETE',
				url: '/default/relation/company/' + id,
				data: deleteData
			}
		}
	}
}
