/**
 * Login reducer
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import {
	notificationTypes,
	workLogTypes,
	userTypes,
	workInAddressTypes,
	workEventTypes,
	apartmentTypes
} from '../actions/types'

const initialState = {
	message: ''
}

// set all the notification messages
export const notificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case workLogTypes.createSuccess:
			return {
				...state,
				message: 'Uusi työkirjaus lisätty!'
			}
		case userTypes.createUserSuccess:
			return {
				...state,
				message: 'Uusi käyttäjä luotu!'
			}

		case workInAddressTypes.createSuccess:
			return {
				...state,
				message:
					'Uusi keikka lisätty. Voit nyt lisätä sille VT:n tai palata Etusivulle.'
			}

		case workEventTypes.updateRelationSuccess:
			return {
				...state,
				message: 'Uusi työkirjaus lisätty.'
			}

		// Initialize notifications
		case notificationTypes.clearNotification:
			return initialState

		case notificationTypes.showNotification:
			return {
				...state,
				message: action.payload.data.message
			}
		case apartmentTypes.sendElectricityFormSuccess: {
			return {
				...state,
				message: 'Sähköposti on lähetetty.'
			}
		}
		default:
			return state
	}
}

export const notificationSelectors = {
	getNotification: (state = initialState) => state.message
}
