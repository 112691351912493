/**
 * Navbar component
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, withRouter, Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import { loginActions } from '../../actions'
import { routerPath as p } from '../../constants'
import { userSelectors } from '../../reducers'
import { persistor } from '../../store'
import PermissionChecker from '../../services/permissionChecker'
import Icon from '../components/Icons'

const createLink = (to, displayName, onClick, openToAll = false) => ({
	to,
	displayName,
	onClick,
	openToAll
})

const createLinks = defaultOnClick => [
	createLink(p.home, 'Etusivu', defaultOnClick, true),
	createLink(p.controlPanel, 'Hallintapaneeli', defaultOnClick),
	createLink(p.editingAttribute, 'Muokkaa työtyyppejä', defaultOnClick),
	createLink(p.electricityPaymentManagement, 'Sähköhyvitys', defaultOnClick),
	createLink(p.editCompany, 'Yritysten hallinta', defaultOnClick),
	createLink(p.editPerson, 'Henkilöiden hallinta', defaultOnClick),
	createLink(p.machines, 'Koneet', defaultOnClick),
	createLink(p.roles, 'Roolit', defaultOnClick),
	createLink(p.materials, 'Materiaalit', defaultOnClick),
	createLink(p.parameters, 'Parametrit', defaultOnClick),
	createLink(p.tooltips, 'Ohjeet', defaultOnClick)
]

const Nav = props => {
	const { user, logout } = props
	const [state, setState] = React.useState({
		open: false
	})

	const toggleDrawer = toggle => event => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return
		}

		setState({ ...state, open: toggle })
	}

	const goBack = () => props.history.goBack()

	const handleLogout = async e => {
		e.preventDefault()
		try {
			// ensure local storage state is cleared on logout to fix stale cache bugs
			await persistor.purge()
		} finally {
			logout()
		}
	}

	const hideBackButtonRoute = [p.home, p.electricityRefundForm]
	const hideBackButton = hideBackButtonRoute.some(
		route =>
			route.split('/')[1].indexOf(props.location.pathname.split('/')[1]) !== -1
	)

	const hideMenuButtonRoute = [p.electricityRefundForm]
	const hideMenuButton = hideMenuButtonRoute.some(
		route =>
			props.location.pathname !== p.home &&
			route.split('/')[1].indexOf(props.location.pathname.split('/')[1]) !== -1
	)

	const hasEditRights = PermissionChecker.canEditSettings(user)
	const links = createLinks(toggleDrawer).filter(
		l => l.openToAll || hasEditRights
	)

	return (
		<div className="nav">
			{hideMenuButton ? null : (
				<Icon iconName="menu" onClick={toggleDrawer(true)} />
			)}
			{hideBackButton ? null : (
				<Icon
					iconName="arrow_back_ios"
					className="back-container"
					onClick={goBack}
				/>
			)}
			<Drawer
				anchor="right"
				open={state.open}
				onClose={toggleDrawer(false)}
				className="nav-bar"
			>
				<Icon iconName="menu" onClick={toggleDrawer(false)} />

				{links.map(({ to, onClick, displayName }) => (
					<Link
						key={`link-item-${to}`}
						className="button-container"
						to={to}
						onClick={onClick}
					>
						<div className="navbutton">{displayName}</div>
					</Link>
				))}

				<Link className="button-container" to="/" onClick={handleLogout}>
					<div className="navbutton" path={p.logout}>
						Kirjaudu ulos
					</div>
				</Link>

				<p
					className="white"
					style={{
						padding: '20px'
					}}
				>
					© Humitor kuivaustekniikka Oy
				</p>
			</Drawer>
		</div>
	)
}

export default withRouter(
	connect(
		state => ({
			user: userSelectors.getUser(state.user)
		}),
		dispatch => ({
			logout: () => dispatch(loginActions.logout)
		})
	)(Nav)
)
