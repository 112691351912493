/**
 * Home component
 * Wrap the root component with layout UI components
 * e.g Navigation, Footer, Modal, Alert...
 *
 * @Elsa <elsa@vertics.co>
 *
 */
import React, { Component, lazy, useState, useEffect } from 'react'
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
	useHistory
} from 'react-router-dom'
import Nav from './Nav'
import Footer from './Footer'
import { routerPath } from '../../constants'
import ProtectedRoute from '../components/auth/ProtectedRoute'
import { history } from '../../reducers'
const Home = lazy(() => import(/*webpackChunkName: "Home"*/ '../../pages/Home'))
const Jobs = lazy(() => import(/*webpackChunkName: "Jobs"*/ '../../pages/Jobs'))
const WorkInAddress = lazy(() =>
	import(/*webpackChunkName: "WorkInAddress"*/ '../../pages/WorkInAddress')
)
const Apartment = lazy(() =>
	import(/*webpackChunkName: "Apartment"*/ '../../pages/Apartment')
)
const CompanyEdit = lazy(() =>
	import(/*webpackChunkName: "CompanyEdit"*/ '../../pages/CompanyEdit')
)
const PersonEdit = lazy(() =>
	import(/*webpackChunkName: "PersonEdit"*/ '../../pages/PersonEdit')
)
const WorkLogPage = lazy(() =>
	import(/*webpackChunkName: "WorkLogPage"*/ '../../pages/WorkLogPage')
)
const ControlPanel = lazy(() =>
	import(/*webpackChunkName: "ControlPanel"*/ '../../pages/ControlPanel')
)
const UserPermission = lazy(() =>
	import(
		/*webpackChunkName: "UserPermission"*/ '../../pages/UserPermission/UserPermission'
	)
)
const InviteUser = lazy(() =>
	import(
		/*webpackChunkName: "InviteUser"*/ '../../pages/ControlPanel/InviteUser'
	)
)
const DeleteUser = lazy(() =>
	import(
		/*webpackChunkName: "DeleteUser"*/ '../../pages/ControlPanel/DeleteUser'
	)
)
const CustomerRegister = lazy(() =>
	import(/*webpackChunkName: "CustomerRegister"*/ '../../pages/EditCustomers')
)
const WorkHours = lazy(() =>
	import(/*webpackChunkName: "WorkHours"*/ '../../pages/WorkHours')
)
const ProductManagement = lazy(() =>
	import(
		/*webpackChunkName: "ProductManagement"*/ '../../pages/ProductManagement'
	)
)
const ElectricityPaymentManagement = lazy(() =>
	import(
		/*webpackChunkName: "ElectricityPaymentManagement"*/ '../../pages/ElectricityPayments'
	)
)
const Pictures = lazy(() =>
	import(
		/*webpackChunkName: "WorkLogPage"*/ '../../pages/WorkInAddress/Pictures'
	)
)
const Documents = lazy(() =>
	import(
		/*webpackChunkName: "WorkLogPage"*/ '../../pages/WorkInAddress/Documents'
	)
)
const Reports = lazy(() =>
	import(/*webpackChunkName: "Reports"*/ '../../pages/Reports')
)
const CreateNewReport = lazy(() =>
	import(
		/*webpackChunkName: "CreateNewReport"*/ '../../pages/Reports/CreateNewReport'
	)
)
const PreviewReport = lazy(() =>
	import(
		/*webpackChunkName: "PreviewReport"*/ '../../pages/Reports/PreviewMeasurementReport'
	)
)
const PreviewJobSpecificationReport = lazy(() =>
	import(
		/*webpackChunkName: "PreviewJobSpecificationReport"*/ '../../pages/Reports/PreviewJobSpecificationReport'
	)
)
const UploadReport = lazy(() =>
	import(/*webpackChunkName: "UploadReport"*/ '../../pages/UploadReport')
)
const ElectricityRefundForm = lazy(() =>
	import(
		/*webpackChunkName: "ElectricityRefundForm"*/ '../../pages/ElectricityRefundForm'
	)
)
const EditingAttribute = lazy(() =>
	import(
		/*webpackChunkName: "EditingAttribute"*/ '../../pages/EditingAttribute'
	)
)
const MachineManagement = lazy(() =>
	import(/*webpackChunkName: "Machines"*/ '../../pages/MachineManagement')
)
const MaterialManagement = lazy(() =>
	import(/*webpackChunkName: "Materials"*/ '../../pages/MaterialManagement')
)
const ParameterManagement = lazy(() =>
	import(/*webpackChunkName: "Parameters"*/ '../../pages/ParameterManagement')
)
const TooltipManagement = lazy(() =>
	import(/*webpackChunkName: "Tooltips"*/ '../../pages/TooltipManagement')
)
const RoleManagement = lazy(() =>
	import(/*webpackChunkName: "Roles"*/ '../../pages/RoleManagement')
)

const Login = lazy(() => import('../../pages/Login'))

const HomeLayout = props => {
	const [mainStyle, setMainStyle] = useState({})
	const history = useHistory()

	const initMainStyle = location => {
		console.log(`location=${location}`)
		let mainStyle = {}
		if (location.includes(routerPath.electricityPaymentManagement)) {
			mainStyle = { width: '1800px' }
		} else if (location.includes(routerPath.materials)) {
			mainStyle = { width: '1000px' }
		}
		setMainStyle(mainStyle)
	}

	useEffect(() => {
		return history.listen(location => {
			initMainStyle(location.pathname)
		})
	}, [history])

	useEffect(() => {
		initMainStyle(window.location.href)
	}, [])

	return (
		<div className="app-home">
			<header>
				<div>
					<Nav />
				</div>
			</header>
			<main style={mainStyle}>{props.children}</main>
			<footer>
				<Footer />
			</footer>
		</div>
	)
}

const RenderSwitchHome = () => {
	return (
		<HomeLayout>
			<Switch>
				<Route path={routerPath.login} component={Login} />
				<Route
					path={routerPath.electricityRefundForm}
					component={ElectricityRefundForm}
				/>

				<ProtectedRoute exact path={routerPath.home} component={Home} />
				<ProtectedRoute exact path={routerPath.job} component={Jobs} />
				<ProtectedRoute
					exact
					path={routerPath.editCompany}
					component={CompanyEdit}
				/>
				<ProtectedRoute
					exact
					path={routerPath.editPerson}
					component={PersonEdit}
				/>
				<ProtectedRoute exact path={routerPath.editJob} component={Jobs} />
				<ProtectedRoute
					exact
					path={routerPath.controlPanel}
					component={ControlPanel}
				/>
				<ProtectedRoute
					exact
					path={routerPath.userPermission}
					component={UserPermission}
				/>
				<ProtectedRoute
					exact
					path={routerPath.customerRegister}
					component={CustomerRegister}
				/>
				<ProtectedRoute
					exact
					path={routerPath.inviteUser}
					component={InviteUser}
				/>
				<ProtectedRoute
					exact
					path={routerPath.deleteUser}
					component={DeleteUser}
				/>
				<ProtectedRoute
					exact
					path={routerPath.workHours}
					component={WorkHours}
				/>
				<ProtectedRoute
					exact
					path={routerPath.productManagement}
					component={ProductManagement}
				/>
				<ProtectedRoute
					exact
					path={routerPath.electricityPaymentManagement}
					component={ElectricityPaymentManagement}
				/>
				<ProtectedRoute
					exact
					path={routerPath.apartment + '/:apartmentId/:workLogEventId?'}
					component={Apartment}
				/>
				<ProtectedRoute
					exact
					path={routerPath.workLog + '/:apartmentId'}
					component={WorkLogPage}
				/>
				<ProtectedRoute
					exact
					path={routerPath.pictures + '/:apartmentId'}
					component={Pictures}
				/>
				<ProtectedRoute
					exact
					path={routerPath.documents}
					component={Documents}
				/>
				<ProtectedRoute exact path={routerPath.reports} component={Reports} />
				<ProtectedRoute
					exact
					path={routerPath.createReport}
					component={CreateNewReport}
				/>
				<ProtectedRoute
					exact
					path={routerPath.uploadReport}
					component={UploadReport}
				/>
				<ProtectedRoute
					exact
					path={routerPath.previewMeasurementReport}
					component={PreviewReport}
				/>
				<ProtectedRoute
					exact
					path={routerPath.previewNewJobSpecificationReport}
					component={PreviewJobSpecificationReport}
				/>
				<ProtectedRoute
					exact
					path={routerPath.workInAddress + '/:id'}
					component={WorkInAddress}
				/>
				<ProtectedRoute
					exact
					path={routerPath.editingAttribute}
					component={EditingAttribute}
				/>
				<ProtectedRoute
					exact
					path={routerPath.machines}
					component={MachineManagement}
				/>
				<ProtectedRoute
					exact
					path={routerPath.materials}
					component={MaterialManagement}
				/>
				<ProtectedRoute
					exact
					path={routerPath.parameters}
					component={ParameterManagement}
				/>
				<ProtectedRoute
					exact
					path={routerPath.tooltips}
					component={TooltipManagement}
				/>
				<ProtectedRoute
					exact
					path={routerPath.roles}
					component={RoleManagement}
				/>
			</Switch>
		</HomeLayout>
	)
}

export default RenderSwitchHome
