import {
	statusAllowedForModification,
	statuses
} from '../models/addressStatuses'
import { User } from '../models/user'

export default class PermissionChecker {
	public static editAllowed(jobStatus: statuses, user: User): boolean {
		if (user.isAdmin) {
			return true
		}
		if (statusAllowedForModification.includes(jobStatus)) {
			return true
		}
		return false
	}

	public static statusChangeAllowed(
		jobStatus: statuses,
		selectedStatus: statuses,
		user: User
	): boolean {
		if (user.isAdmin) {
			return true
		}
		if (
			statusAllowedForModification.includes(jobStatus) &&
			selectedStatus != statuses.READY
		) {
			return true
		}
		return false
	}

	public static canEditSettings(user: User): boolean {
		return user && user.canEditWorkLog
	}
}
